import { getHeaders } from '.';
import { getTokenFromLSService, removeTokenFromLSService, setTokenToLSService, setUserToLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeConfigsDoc, typeResponseData, typeUser, unauthenticatedConfig } from '@monorepo/models';

const base = pointer.user;

export const changeEmailService = async (newEmail: string): Promise<typeResponseData | null> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(`${base}/token`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ newEmail })
        });
        const data = await response.json() as typeResponseData;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const changePswService = async (team: number, psw: string | null, newPsw: string, id: string | null): Promise<typeResponseData | null> => {
    try {
        // no token
        const response = await fetch(`${base}/token`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ team, psw, newPsw, id })
        });
        const data = await response.json() as typeResponseData;
        if (data && data.success && data.newToken) setTokenToLSService(data.newToken);
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const changePswOtherUserService = async (email: string): Promise<[string, boolean]|null> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(`${base}/token`, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ email })
        });
        const data = await response.json() as typeResponseData;
        if (!data || !data.newPassword) return null;
        data.emailSuccess = !!data.emailSuccess;
        return [data.newPassword, data.emailSuccess];
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const deleteMyAccountService = async (): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(base, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ myUser: true })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteUserService = async (userId: number): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(base, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ userId })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const editUserService = async (email: string, isActive: boolean, roles: number[]): Promise<typeUser | null> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(base, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ email, isActive, roles })
        });
        const data = await response.json() as typeResponseData;
        if (!data || !data.success || !data.user) return null;
        return data.user;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getEmailByEmailLink = async (congregation: string, id: string): Promise<string | null> => {
    try {
        // no token
        const response = await fetch(`${base}/recovery?id=${id}&team=${congregation}`, {
            method: 'GET',
            headers: getHeaders()
        });
        const data = await response.json() as typeResponseData;
        if (!data || !data.success || !data.email) return null;
        return data.email;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getUserByTokenService = async (token: string = ''): Promise<{ user: typeUser | null; config: typeConfigsDoc | null; logout: boolean; }> => {
    try {
        if (!getTokenFromLSService() && !token) return { user: null, config: null, logout: true };
        const response = await fetch(base, {
            method: 'GET',
            headers: !!token ? getHeaders(null, token) : getHeaders()
        });
        const data = await response.json() as typeResponseData;
        if (!data) return { user: null, config: null, logout: false };
        if (!data.config) {
            data.config = unauthenticatedConfig;
        }
        if (!data?.success || !data?.user) {
            if (data && !data.success) removeTokenFromLSService();
            return { user: null, config: data.config, logout: true };
        }
        setUserToLSService(data.user);
        return {
            user: data.user,
            config: data.config,
            logout: false
        };
    } catch (error) {
        console.log(error);
        return { user: null, config: null, logout: false };
    }
}

export const getUsersService = async (): Promise<typeUser[] | null> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(`${base}/all`, {
            method: 'GET',
            headers: getHeaders()
        });
        const data = await response.json() as typeResponseData;
        if (!data || !data.success || !data.users) return null;
        return data.users;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const loginService = async (email: string, password: string, recaptchaToken: string): Promise<typeResponseData | null> => {
    try {
        // no token
        const response: any = await fetch(`${base}/token`, {
            method: 'POST',
            headers: getHeaders(recaptchaToken),
            body: JSON.stringify({ email, password })
        });
        const data = await response.json() as typeResponseData;
        if (!!data?.success && !!data?.newToken) setTokenToLSService(data.newToken);
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const logoutAllService = async (): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(`${base}/token`, {
            method: 'DELETE',
            headers: getHeaders()
        });
        const data = await response.json() as typeResponseData;
        if (!data || !data.success || !data.newToken) return false;
        setTokenToLSService(data.newToken);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const registerUserService = async (team: number, email: string, invitationId: string, password: string, recaptchaToken: string): Promise<typeResponseData | null> => {
    try {
        // no token
        const response = await fetch(base, {
            method: 'POST',
            headers: getHeaders(recaptchaToken),
            body: JSON.stringify({ email, invitationId, password, team })
        });
        const data = await response.json() as typeResponseData;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const registerUserAdminsService = async (email: string, password: string): Promise<typeResponseData | null> => {
    try {
        // no token
        const response = await fetch(base, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ email, password })
        });
        const data = await response.json() as typeResponseData;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const sendLinkToRecoverAccount = async (email: string): Promise<any> => {
    try {
        // no token
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({ email })
        });
        const data = await response.json() as typeResponseData;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const setUserPersonalDataService = async (userId: number, userEmail: string, name: string, lastName: string, phoneNumber: string): Promise<typeResponseData | null> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(`${base}/personal-data`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({
                lastName,
                name,
                phoneNumber,
                userEmail,
                userId
            })
        });
        const data = await response.json() as typeResponseData;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}
