import { getHeaders } from '.';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeNotificationsDoc, typeResponseData } from '@monorepo/models';

class NotificationServices {
    private _base = pointer.notification;
    //
    GetDoc = async (): Promise<typeNotificationsDoc | null> => {
        try {
            if (!getTokenFromLSService()) throw new Error('No autenticado');
            const response = await fetch(this._base, {
                headers: getHeaders()
            });
            const data = await response.json() as typeResponseData;
            return data?.notificationDoc ?? null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    SetOptions = async (notificationDoc: typeNotificationsDoc): Promise<boolean> => {
        try {
            if (!getTokenFromLSService()) throw new Error('No autenticado');
            const response = await fetch(this._base, {
                headers: getHeaders(),
                method: 'PATCH',
                body: JSON.stringify({ notificationDoc })
            });
            const data = await response.json() as typeResponseData;
            return data?.success;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}

export const notificationServices = new NotificationServices();
