import { assignHTHTerritoryService } from '../../../../services';
import { BsTrash } from 'react-icons/bs';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../../store';
import { typeHTHTerritory, typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode } from '../../../../custom-hooks';
import Select from 'react-select';

type propsType = {
    setTerritoryToShow: Dispatch<SetStateAction<typeHTHTerritory | null>>;
    t: typeHTHTerritory;
    territoryToShow: typeHTHTerritory | null;
    users: typeUser[] | null;
}

export const HTHAssignmentsByTerritory: FC<propsType> = ({ setTerritoryToShow, t, territoryToShow, users }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userToAdd, setUserToAdd] = useState<typeUser | null>(null);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();

    const showError = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'alert',
            title: 'Error',
            message: 'Algo falló al cambiar las asignaciones',
            animation: 2
        }));
    }

    const assign = (toAssign: number): void => {
        if (!userToAdd) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a asignar el territorio ${toAssign} a ${userToAdd.email}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                setIsLoading(true);
                const success = await assignHTHTerritoryService(userToAdd, toAssign, null, false);
                setIsLoading(false);
                dispatch(hideLoadingModalReducer());
                if (!success) {
                    showError();
                }
                setUserToAdd(null);
            }
        }));
    }

    const unassign = (user: typeUser, toUnassign: number): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a desasignar el territorio ${toUnassign} a ${user.email}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                setIsLoading(true);
                const success = await assignHTHTerritoryService(user, null, toUnassign, false);
                setIsLoading(false);
                dispatch(hideLoadingModalReducer());
                if (!success) {
                    showError();
                }
            }
        }));
    }

    return (
        <div className={'my-5'}>
            <div className={`card ${isDarkMode ? 'text-white' : ''}`}>
                <div className={`card-header ${isDarkMode ? 'bg-success' : ''} d-flex justify-content-between`}>
                    <div className={'fw-bold'}>
                        TERRITORIO {t.territoryNumber} - Edificios: {t.map.polygons.map(x => x.buildings?.length || 0).reduce((a, n) => a + n, 0)}
                    </div>
                </div>
                <div className={`card-body ${isDarkMode ? 'bg-dark' : ''}`}>
                    {users
                        ?.filter(u => u.hthAssignments?.some(a => a.toString() === t.territoryNumber))
                        .sort((u1, u2) => u1.email.localeCompare(u2.email))
                        .map(u =>
                        <div key={u.id} className={'d-flex justify-content-between pointer hover-primary'}
                            onClick={() => isLoading ? null : unassign(u, parseInt(t.territoryNumber))}
                        >
                            <h5>
                                {u.name} {u.lastName} - {u.email}
                            </h5>
                            <div className={'me-3'}>
                                {!isLoading &&
                                    <BsTrash size={'1.3rem'} />
                                }
                            </div>
                        </div>
                    )}
                </div>
                <div className={`card-footer d-flex justify-content-start ${isDarkMode ? 'bg-dark' : ''}`}>
                    <button className={'btn btn-general-blue me-3'}
                        style={{ width: '200px' }}
                        onClick={() => assign(parseInt(t.territoryNumber))}
                        disabled={!userToAdd || isLoading}
                    >
                        Agregar
                    </button>
                    <div className={'w-100'}>
                        <Select options={users?.filter(u => !t.assignments.includes(u.id)) ?? []}
                            className={isDarkMode ? 'text-black' : ''}
                            isClearable
                            getOptionLabel={u => `${u.name} ${u.lastName}`}
                            getOptionValue={u => u?.id?.toString()}
                            value={userToAdd}
                            onChange={u => setUserToAdd(u)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
