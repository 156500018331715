import { BoardSection } from './subcomponents/BoardSections';
import { BoardAddSection } from './subcomponents/BoardAddSection';
import { getBoardSectionsService } from '../../../services';
import { H2, Loading } from '../../_commons';
import { typeBoardSection } from '@monorepo/models';
import { useEffect, useState } from 'react';

export const AdminsBoard = () => {
    const [counterReload, setCounterReload] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [sections, setSections] = useState<typeBoardSection[] | null>(null);

    useEffect(() => {
        getBoardSectionsService().then(_boardSections => {
            setIsLoading(false);
            if (_boardSections) setSections(_boardSections);
        });
    }, [counterReload]);

    return (<>
        <H2 title={'ADMINISTRACIÓN TABLERO'} />

        {isLoading && <Loading />}

        <div>
            {sections?.sort((s1, s2) => s1.order - s2.order).map(section =>
                <BoardSection key={section.title}
                    section={section}
                    sections={sections}
                    setCounterReload={setCounterReload}
                />
            )}
        </div>
        {!isLoading &&
            <BoardAddSection
                sections={sections}
                setCounterReload={setCounterReload}
            />
        }
    </>)
}
