import { BsArrowBarDown } from 'react-icons/bs';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { getUsersService } from '../../../services';
import { H2, Loading, UsersSelector, WarningToaster } from '../../_commons';
import { io, Socket } from 'socket.io-client';
import { SERVER, socketIoEvents } from '../../../app-config';
import { setValuesAndOpenAlertModalReducer } from '../../../store';
import { typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, useGetIsMobile } from '../../../custom-hooks';
import { UsersCard } from './subcomponents/UsersCard';
import { UsersNewUser } from './subcomponents/UsersNewUser';
import { UsersRolesModal } from './subcomponents/UsersRolesModal';

const socket: Socket = io(SERVER, { withCredentials: true });

type propsType = {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const AdminsUsers: FC<propsType> = ({ setIsLoading }) => {
    const [emailSearchInputText, setEmailSearchInputText] = useState('');
    const [showNewUser, setShowNewUser] = useState(false);
    const [showRolesModal, setShowRolesModal] = useState(false);
    const [users, setUsers] = useState<typeUser[] | null>(null);
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    const usersToShow: typeUser[] = useMemo(() =>
        users?.filter(x =>
            x.email.toLowerCase().includes(emailSearchInputText.toLowerCase())
            || x.name?.toLowerCase().includes(emailSearchInputText.toLowerCase())
            || x.lastName?.toLowerCase().includes(emailSearchInputText.toLowerCase())
            || `${x.name ?? ''} ${x.lastName ?? ''}`.toLowerCase().includes(emailSearchInputText.toLowerCase())
            || `${x.lastName ?? ''} ${x.name ?? ''}`.toLowerCase().includes(emailSearchInputText.toLowerCase())
        ) ?? []
    , [emailSearchInputText, users]);

    useEffect(() => {
        getUsersService().then((users0: typeUser[] | null) => {
            if (!users0) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode:'alert',
                    title: 'Problemas',
                    message: 'Algo falló al cargar los usuarios; refrescar',
                    animation: 2
                }));
                return;
            }
            setUsers(users0);
        })
    }, [dispatch]);

    useEffect(() => {
        socket.on(socketIoEvents.userChange, (congregation: number) => {
            if (congregation !== user.congregation) return;
            setIsLoading(true);
            getUsersService().then(users => {
                setIsLoading(false);
                if (users) setUsers(users);
            });
        });
        return () => { socket.off(socketIoEvents.userChange) };
    }, [user.congregation]);

    return (<>

        <H2 title={'USUARIOS'} />
    
        {!users && <Loading mt={'50px'} />}

        {users && (!socket || !socket.connected) &&
            <div style={{ marginTop: '30px', position: 'fixed', zIndex: 4 }}>
                <WarningToaster
                    bodyText={'Refrescar la página y verificar que hay internet'}
                    headerText={<strong>Hay un problema de conexión</strong>}
                />
            </div>
        }

        {user.isAdmin && <>
            <div className={`${isMobile ? '' : 'd-flex justify-content-center'} mt-5 mb-3`}>
                <button className={`btn btn-general-blue btn-size12 ${isMobile ? 'd-block mx-auto mb-1' : 'me-2'}`}
                    style={{ width: '350px', minHeight: '60px' }}
                    onClick={() => setShowRolesModal(true)}
                    disabled={showNewUser}
                >
                    VER ROLES <FaExternalLinkAlt size={isMobile ? '1rem' : '.9rem'} />
                </button>
                <button className={`btn btn-general-blue btn-size12 ${isMobile ? 'd-block mx-auto mt-1' : 'ms-2'}`}
                    style={{ width: '350px', minHeight: '60px' }}
                    onClick={() => setShowNewUser(true)}
                    disabled={showNewUser}
                >
                    NUEVO USUARIO <BsArrowBarDown size={isMobile ? '2rem' : '1.4rem'} />
                </button>
            </div>

            {showRolesModal && users &&
                <UsersRolesModal
                    closeModal={() => setShowRolesModal(false)}
                    users={users}
                />
            }

            {showNewUser &&
                <UsersNewUser
                    setIsLoading={setIsLoading}
                    setShowNewUser={setShowNewUser}
                />
            }
        </>}

        {!!users?.length && !showNewUser &&
            <div className={'mt-5'}>
                <UsersSelector
                    setQuery={setEmailSearchInputText}
                    usersToShow={usersToShow}
                />
            </div>
        }

        {!showNewUser && !!usersToShow?.length && usersToShow.map(user =>
            <UsersCard key={user.id}
                currentUser={user}
                setIsLoading={setIsLoading}
            />
        )}
    </>)
}
