import { changeDarkModeReducer } from '../../store'
import { generalBlue } from '../../app-config'
import { useDispatch } from 'react-redux'
import { useGetIsDarkMode, useGetIsMobile } from '../../custom-hooks'

export const DarkModeButton = () => {
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    
    return (
        <div className={'form-check form-switch'} style={{ position: 'fixed', bottom: '20px' }}>

            <input id={"darkModeSwitch"}
                className={'form-check-input'}
                checked={isDarkMode}
                onChange={() => dispatch(changeDarkModeReducer())}
                type={'checkbox'}
            />

            <label htmlFor={'darkModeSwitch'}
                className={'form-check-label'}
                style={{ color: isDarkMode ? 'white' : generalBlue }}
            >
                <b> {isMobile ? '' : (isDarkMode ? "Oscuro" : "Claro")} </b>
            </label>

        </div>
    )
}
