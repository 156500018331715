import { archiveBoardFileService, deleteBoardItemService, reorderBoardItemsService } from '../../../../services';
import { boardItemTypes, typeBoardItem, typeBoardSection } from '@monorepo/models';
import { Dispatch, FC, SetStateAction } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { generalRed } from '../../../../app-config';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../../store';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile } from '../../../../custom-hooks';

type propsType = {
    section: typeBoardSection;
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardSectionsItems: FC<propsType> = ({ section, setCounterReload }) => {
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const reorder = async (item: typeBoardItem, up: boolean) => {
        if (!section.items) return;
        dispatch(showLoadingModalReducer());
        const reorderedItems = section.items.map(i => ({
            ...i,
            order:
                up ?
                i.id === item.id ? i.order - 1 : i.order === item.order - 1 ? i.order + 1 : i.order
                :
                i.id === item.id ? i.order + 1 : i.order === item.order + 1 ? i.order - 1 : i.order
        }));
        const success = await reorderBoardItemsService(section.id, section.title, reorderedItems);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo guardar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    const archiveFile = (item: typeBoardItem) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar Archivar',
            message: `Se va a archivar '${item.originalName}' de '${section.title}'. Seguirá siendo descargable solo para administradores pero no será visible desde el Tablero.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await archiveBoardFileService(section.id, section.title, item, false)
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setCounterReload(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Hubo un error',
                        message: 'Algo falló y no se pudo archivar',
                        mode: 'alert',
                        animation: 2
                    }));
                }
            }
        }));
    }

    const deleteItem = (item: typeBoardItem) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar Eliminar',
            message: `Se va a eliminar el Ítem de tipo '${boardItemTypes.find(t => t.type === item.type)?.label}' de la sección '${section.title}'. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteBoardItemService(section.id, section.title, item);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setCounterReload(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Hubo un error',
                        message: 'Algo falló y no se pudo eliminar',
                        mode: 'alert',
                        animation: 2
                    }));
                }
            }
        }));
    }

    return (
        <div className={'container mb-4'}>
            {/* <h5 className={'mb-3'}>
                Ítems mostrándose:
            </h5> */}
            {!!section.items.filter(i => !i.archived).length ?
                <div className={'table-responsive'}>
                    <table className={`table table-bordered ${isDarkMode ? 'table-dark' : 'table-light'}`}>
                        <thead>
                            <tr>
                                <th className={'text-center'}>Reordenar</th>
                                <th className={'text-center'}>Orden</th>
                                <th>Tipo</th>
                                <th>Fecha</th>
                                <th>Nombre</th>
                                <th className={'text-center'}>Archivar</th>
                                <th className={'text-center'}>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {section.items.filter(i => !i.archived).sort((i1, i2) => i1.order - i2.order).map(item =>
                                <tr key={item.id}>
                                    <td className={'text-center'}>
                                        <button className={'btn btn-link p-0 mb-2 ms-2'}
                                            onClick={() => reorder(item, true)}
                                            disabled={section.items.filter(i => !i.archived).length < 2 || item.order === 1}
                                        >
                                            <FaArrowUp size={isMobile ? 15 : 25} color={(section.items.filter(i => !i.archived).length < 2 || item.order === 1) ? 'grey' : generalRed} />
                                        </button>
                                        <button className={'btn btn-link p-0 mb-2 ms-2'}
                                            onClick={() => reorder(item, false)}
                                            disabled={section.items.filter(i => !i.archived).length < 2 || item.order === section.items.filter(i => !i.archived).length}
                                        >
                                            <FaArrowDown size={isMobile ? 15 : 25} color={(section.items.filter(i => !i.archived).length < 2 || item.order === section.items.filter(i => !i.archived).length) ? 'grey' : generalRed} />
                                        </button>
                                    </td>
                                    <td className={'text-center'}>{item.order}</td>
                                    <td>
                                        {boardItemTypes.find(t => t.type === item.type)?.label ?? ''}
                                    </td>
                                    <td>{new Date(item.id).toLocaleString()}</td>
                                    <td>{item.originalName}</td>
                                    <td className={'text-center'}>
                                        {item.type === 'file' &&
                                            <button className={'btn btn-general-red'}
                                                onClick={() => archiveFile(item)}
                                            >
                                                Archivar
                                            </button>
                                        }
                                    </td>
                                    <td className={'text-center'}>
                                        <button className={'btn btn-general-red'}
                                            onClick={() => deleteItem(item)}
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                :
                <>No hay</>
            }
        </div>
    )
}
