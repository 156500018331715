import { addCartAssignmentService } from '../../../../services/cartServices';
import { daysOfTheWeekP, generalRed, months } from '../../../../app-config';
import { getPriorityPoints } from '../../../../services';
import { getTimeFromPeriodObject } from '@monorepo/helpers';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../../store';
import { priorities, typeCartAvailability, typeCartAssignment, typeCartsDoc, typeCartParticipant, typeCartPeriod, typeCartPoint } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetShowingLoadingModal } from '../../../../custom-hooks';
import { useState, FC, Dispatch, SetStateAction, useMemo } from 'react';
import Select from 'react-select';

type propsType = {
    cartDoc: typeCartsDoc;
    closeModal: () => void;
    newAssignment: typeCartAssignment | null;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartAssignmentAdd: FC<propsType> = ({ cartDoc, closeModal, newAssignment, setRefreshCounter }) => {
    const [month, setMonth] = useState<number | null>(newAssignment?.month ?? new Date().getMonth());
    const [participants, setParticipants] = useState<(Partial<typeCartParticipant> & { userId: number; } )[]>([]);
    const [period, setPeriod] = useState<typeCartPeriod | null>(newAssignment ? newAssignment as typeCartPeriod : null);
    const [point, setPoint] = useState<typeCartPoint | null>(newAssignment ? ({ ...newAssignment, id: newAssignment.pointId } as typeCartPoint) : null);
    const [weekday, setWeekday] = useState(newAssignment?.weekday ?? 1);
    const [year, setYear] = useState<number | null>(newAssignment?.year ?? new Date().getFullYear());
    const dispatch = useDispatch();
    const showingLoadingModal = useGetShowingLoadingModal();

    const isValid: boolean = useMemo(() =>
        month !== null && participants.length === 2 && !!period && !!point && weekday !== null && !!year
        // && new Date(year, month) >= new Date(new Date().getFullYear(), new Date().getMonth())
        && (period.startHour < period.endHour || (period.startHour === period.endHour && period.startMinutes < period.endMinutes))
        && !cartDoc.assignments.some(a => {
            if (a.year === year && a.month === month && a.weekday === weekday && a.pointId === point.id) {
                const existingStart = (a.startHour ?? 0) * 60 + (a.startMinutes ?? 0);
                const existingEnd = (a.endHour ?? 0) * 60 + (a.endMinutes ?? 0);
                const newStart = period.startHour * 60 + period.startMinutes;
                const newEnd = period.endHour * 60 + period.endMinutes;
                return newStart < existingEnd && newEnd > existingStart;
            } else {
                return false;
            }
        })
        && !cartDoc.assignments.some(a => {
            const participant = a.participants.find(p => p.userId === participants[0].userId);
            if (!participant) return false;
            if (a.year === year && a.month === month && a.weekday === weekday) {
                const existingStart = (a.startHour ?? 0) * 60 + (a.startMinutes ?? 0);
                const existingEnd = (a.endHour ?? 0) * 60 + (a.endMinutes ?? 0);
                const newStart = period.startHour * 60 + period.startMinutes;
                const newEnd = period.endHour * 60 + period.endMinutes;
                return newStart < existingEnd && newEnd > existingStart;
            }
            return false;
        })
    , [cartDoc.assignments, month, participants, period, point, weekday, year]);

    const suggestions: typeCartAssignment[] = useMemo(() => {
        if (!month || !year) return [];
        const suggestions: typeCartAssignment[] = [];
        const availabilities: { key: string; availability: typeCartAvailability; participants: typeCartParticipant[]; }[] = [];
        cartDoc.participants?.filter(p => p.isEnabled).forEach(p => {
            p.availabilities.forEach(a => {
                const key = `${a.pointId}|${a.weekday}|${a.startHour}|${a.startMinutes}`;
                let exists = false;
                for (let i = 0; i < availabilities.length; i++) {
                    const a = availabilities[i];
                    if (a.key === key) {
                        exists = true;
                        a.participants.push(p);
                    }
                }
                if (!exists) {
                    availabilities.push({
                        key,
                        availability: a,
                        participants: [p]
                    })
                }
            });
        });
        availabilities.forEach((a, i) => {
            if (a.participants.length >= 2) {
                a.participants.sort((p1, p2) => getPriorityPoints(p2, cartDoc.assignments, month, year) - getPriorityPoints(p1, cartDoc.assignments, month, year));
                const point1 = cartDoc.points?.find(p => p.id === a.availability.pointId);
                const period1 = point1?.enabledPeriods.find(p => p.startHour === a.availability.startHour && p.startMinutes === a.availability.startMinutes);
                if (!point1 || !period1) return;
                if (!cartDoc.assignments.some(x => x.year === year && x.month === month && x.pointId === point1.id && x.weekday === a.availability.weekday && x.startHour === a.availability.startHour && x.startMinutes === a.availability.startMinutes)) {
                    suggestions.push({
                        endHour: period1.endHour,
                        endMinutes: period1.endMinutes,
                        id: Date.now() + i * 1000,
                        name: point1.name,
                        pointId: point1.id,
                        startHour: period1.startHour,
                        startMinutes: period1.startMinutes,
                        weekday: a.availability.weekday,
                        month,
                        wasDeleted: false,
                        year,
                        participants: [a.participants[0], a.participants[1]]
                    });
                }
            }
        });
        return suggestions;
    }, [cartDoc.assignments, cartDoc.participants, cartDoc.points, month, year]);

    const availableParticipants: typeCartParticipant[] = useMemo(() =>
        cartDoc.participants?.filter(p =>
            p.isEnabled
            && p.availabilities.some(a =>
                a.pointId === point?.id
                && a.weekday === weekday
                && period
                && getTimeFromPeriodObject(a as typeCartPeriod) === getTimeFromPeriodObject(period!)
            )
        ) ?? []
    , [cartDoc.participants, period, point, weekday]);

    const create = async () => {
        if (!isValid || month === null || !period || !point || !year) return;
        const assignment: typeCartAssignment = {
            address: point.name,
            endHour: period.endHour,
            endMinutes: period.endMinutes,
            id: 0,
            month,
            name: point.name,
            participants,
            pointId: point.id,
            startHour: period.startHour,
            startMinutes: period.startMinutes,
            wasDeleted: false,
            weekday,
            year
        };
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a agregar una asignación en ${point.name} para el mes ${months[month]}/${year} los días ${daysOfTheWeekP[weekday]} ${getTimeFromPeriodObject(period)} horas, para ${participants[0].name} ${participants[0].lastName} y ${participants[1].name} ${participants[1].lastName}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await addCartAssignmentService(assignment);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    closeModal();
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo agregar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div className={'row mb-3'}>
                        <div className={'col-2'}>
                            <label className={'form-label'}>
                                Mes
                            </label>
                        </div>
                        <div className={'col-10'}>
                            <input type={'month'}
                                className={'form-control'}
                                value={!!year && month !== null ? `${year}-${(month + 1).toString().padStart(2, '0')}` : ''}
                                onChange={e => {
                                    const data = e.target.value?.split('-');
                                    if (data.length) {
                                        setYear(parseInt(data[0]));
                                        const m = parseInt(data[1]);
                                        setMonth(m - 1);
                                    } else {
                                        setYear(null);
                                        setMonth(null);
                                    }
                                }}
                                disabled={!!newAssignment}
                            />
                        </div>
                    </div>
                    <div className={'row mb-3'}>
                        <div className={'col-2'}>
                            <label className={'form-label'}>
                                Punto
                            </label>
                        </div>
                        <div className={'col-10'}>
                            <Select options={cartDoc.points?.filter(p => !p.wasDeleted)}
                                getOptionLabel={point => point ? `${point.name}` : ''}
                                getOptionValue={point => point?.id?.toString() ?? ''}
                                value={point}
                                onChange={point => setPoint(point)}
                                isDisabled={!!newAssignment}
                            />
                        </div>
                    </div>
                    <div className={'row mb-3'}>
                        <div className={'col-2'}>
                            <label className={'form-label'}>
                                Días
                            </label>
                        </div>
                        <div className={'col-10'}>
                            <select className={'form-control'}
                                value={weekday}
                                onChange={e => setWeekday(parseInt(e.target.value))}
                                disabled={!!newAssignment}
                            >
                                <option value={''} disabled>Selecciona un día</option>
                                {Array.from({ length: 7 }, (_, i) => i).map(d =>
                                    <option key={d} value={d}>{daysOfTheWeekP[d]}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className={'row mb-3'}>
                        <div className={'col-2'}>
                            <label className={'form-label'}>
                                Horario
                            </label>
                        </div>
                        <div className={'col-10'}>
                            <Select options={point?.enabledPeriods?.sort((p1, p2) => p1.startHour - p2.startHour || p1.startMinutes - p2.startMinutes)}
                                getOptionLabel={period => period ? `${getTimeFromPeriodObject(period)}` : ''}
                                getOptionValue={period => period?.id?.toString() ?? ''}
                                value={period}
                                onChange={period => setPeriod(period)}
                                isDisabled={!!newAssignment}
                            />
                        </div>
                    </div>
                    <div className={'mb-3'}>
                        {!!availableParticipants.length ?
                            <>
                            <label className='form-label mt-2'>
                                <strong>Hermanos Disponibles:</strong>
                            </label>
                            <section className='d-flex justify-content-center align-items-center flex-column flex-wrap gap-2'>
                                {availableParticipants.map(participant =>
                                    <div key={participant.userId}
                                        className={`card w-100 text-center p-2 shadow cursor-pointer user-select-none c-pointer ${participants.includes(participant) ? 'bg-success text-white' : ''}`}
                                        onClick={() => {
                                            let newParticipantes;
                                            if (participants.includes(participant)) {
                                                newParticipantes = participants.filter((participante) => participante !== participant);
                                            } else {
                                                newParticipantes = [...participants, participant];
                                            }
                                            // Eliminar duplicados
                                            const uniqueParticipantes = Array.from(new Set(newParticipantes));
                                            setParticipants(uniqueParticipantes);
                                        }}
                                    >
                                        <strong>
                                            {participant.name} {participant.lastName} - {priorities.find(x => x.value === participant.priority)?.label}
                                        </strong>
                                    </div>
                                )}
                            </section>
                            {availableParticipants.length === 1 &&
                                <div className={'text-danger p-2 mt-2'}>
                                    Hay solo un hermano disponible para este Punto, Día y Horario
                                </div>
                            }
                            </>
                            :
                            <>
                            {weekday !== null && !!period && month !== null && point &&
                                <div className={'text-white p-2'} style={{ backgroundColor: generalRed }}>
                                    No hay hermanos disponibles para este Punto, Día y Horario
                                </div>
                            }
                            </>
                        }
                    </div>
                </div>
                <div className={'col-md-6'}>
                    <div className={'mb-3'}>
                        <div className={'mb-3 text-center'}>
                            <h4>Sugerencias de Asignaciones:</h4>
                        </div>
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            {suggestions.map(suggestion =>
                                <div key={suggestion.id}
                                    className={'card custom-card p-3 mb-2 hover'}
                                    onClick={() => {
                                        const sugPoint = cartDoc.points?.find(p => p.id === suggestion.pointId) ?? null;
                                        const sugPeriod = sugPoint?.enabledPeriods.find(p => p.startHour === suggestion.startHour && p.startMinutes === suggestion.startMinutes) ?? null;
                                        if (!sugPoint || !sugPeriod) return;
                                        setPoint(sugPoint);
                                        setPeriod(sugPeriod);
                                        setParticipants(suggestion.participants);
                                        setWeekday(suggestion.weekday);
                                    }}
                                >
                                    <p>
                                        <strong>Punto:</strong> {suggestion.name}
                                    </p>
                                    <p>
                                        <strong>Día:</strong> {daysOfTheWeekP[suggestion.weekday]}
                                    </p>
                                    <p>
                                        <strong>Horario:</strong> {getTimeFromPeriodObject(suggestion as typeCartPeriod)}
                                    </p>
                                    <strong>Hermanos disponibles:</strong>{' '}
                                    <ul>
                                        {suggestion.participants.map(p =>
                                            <li key={p.userId}>
                                                {p.name} {p.lastName}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row mt-2 mb-3'}>
                {!showingLoadingModal &&
                    <>
                    <div className={'col-3 pe-1'}>
                        <button className={'btn btn-general-blue w-100'}
                            onClick={create}
                            disabled={!isValid}
                        >
                            Agregar Asignación
                        </button>
                    </div>
                    <div className={'col-3 ps-1'}>
                        <button className={'btn btn-secondary w-100'}
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                    </div>
                    </>
                }
            </div>
        </div>
    )
}
