import { BsCalendar } from 'react-icons/bs';
import { CartCalendarItem } from './CartCalendarItem';
import { months } from '../../../../../app-config';
import { typeCartAssignment, typeCartsDoc } from '@monorepo/models';
import { useState, FC, Dispatch, SetStateAction } from 'react';

type propsType = {
    cartDoc: typeCartsDoc;
    deleteAssignment: (assignment: typeCartAssignment) => void;
    nextMonth: boolean;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
    thisMonth: boolean;
}

export const CartCalendar: FC<propsType> = ({ cartDoc, deleteAssignment, nextMonth, setRefreshCounter, thisMonth }) => {
    const [month, setMonth] = useState<number | null>(new Date().getMonth());
    const [year, setYear] = useState<number | null>(new Date().getFullYear());

    return (
        <div className={'container mt-5'}>
            {(thisMonth || nextMonth) ?
                <h2 className={'btn-general-blue text-center py-3 mb-4'}>
                    {month && `${months[month]} ${year}`}
                </h2>
                :
                <div className={'ps-5'}>
                    <h3>Calendario de Asignaciones <BsCalendar className={'ms-1 mb-2'} /></h3>
                    <div className={'mb-4'}>
                        <label className={'form-label'} htmlFor={'monthSelect'}>
                            Selecciona el mes:
                        </label>
                        <input type={'month'}
                            id={'monthSelect'}
                            className={'form-control'}
                            value={year && month !== null ? `${year}-${(month + 1).toString().padStart(2, '0')}` : ''}
                            onChange={e => {
                                const data = e.target.value?.split('-');
                                if (data.length) {
                                    setYear(parseInt(data[0]));
                                    const m = parseInt(data[1]);
                                    setMonth(m - 1);
                                } else {
                                    setYear(null);
                                    setMonth(null);
                                }
                            }}
                            style={{ maxWidth: '300px' }}
                        />
                        <div className={'mt-3'}>
                            <strong>Seleccionado:</strong> {month !== null && months[month]} {!!year && year}
                        </div>
                    </div>
                </div>
            }
            {!!month && !!year && cartDoc.points?.filter(p => !p.wasDeleted).map(p =>
                <CartCalendarItem key={p.id}
                    cartDoc={cartDoc}
                    deleteAssignment={deleteAssignment}
                    point={p}
                    month={month}
                    setRefreshCounter={setRefreshCounter}
                    year={year}
                />
            )}
        </div>
    )
}
