import { CartAssignmentAdd } from '../CartAssignmentAdd';
import { FC, Dispatch, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import { typeCartAssignment, typeCartsDoc } from '@monorepo/models';
import { useGetIsDarkMode } from '../../../../../custom-hooks';

type propsType = {
    cartDoc: typeCartsDoc;
    closeModal: () => void;
    newAssignment: typeCartAssignment;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartCalendarModalAdd: FC<propsType> = ({
    cartDoc, closeModal, newAssignment, setRefreshCounter
}) => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <Modal show={true} onHide={closeModal} size={'lg'}>
            <Modal.Header closeButton className={isDarkMode ? 'bg-dark text-white' : ''}>
                <Modal.Title>Agregar Asignación</Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark text-white' : ''}>
                <CartAssignmentAdd
                    cartDoc={cartDoc}
                    closeModal={closeModal}
                    newAssignment={newAssignment}
                    setRefreshCounter={setRefreshCounter}
                />
            </Modal.Body>
        </Modal>
    )
}
