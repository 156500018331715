"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unauthenticatedConfig = void 0;
///////////////////////////////////////////////////////////////////////////////////////////////////
exports.unauthenticatedConfig = {
    congregation: 0,
    date: 0,
    dbBackupLastDate: '',
    isDisabledCloseHthFaces: true,
    isDisabledEditHthMaps: true,
    isDisabledHthFaceObservations: true,
    isSharingHthAll: false,
    name: '',
    numberOfTerritories: 0,
    usingLettersForBlocks: false
};
