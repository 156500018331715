import { FC } from 'react';
import { useGetIsMobile } from '../../custom-hooks';
import QRCode from 'qrcode.react';

type propsType = {
    closeModal: () => void;
    title: string;
    url: string;
}

export const QrCode: FC<propsType> = ({ closeModal, title, url }) => {
    const isMobile = useGetIsMobile();

    return (
        <>
        <div className={'modal show d-block'} tabIndex={-1} role='dialog'>
            <div className={'modal-dialog modal-lg modal-dialog-centered'}>
                <div className={'modal-content'}>
                    <div className={'modal-header'}>
                        <h3 className={'modal-title'}>
                            {title}
                        </h3>
                        <button className={'btn-close'} onClick={closeModal}></button>
                    </div>
                    <div className={'modal-body d-flex justify-content-center text-center'}>
                        <div className={''} style={{ width: isMobile ? '100%' : '400px' }}>
                            <QRCode
                                value={url}
                                size={256}
                                style={{ width: '100%', maxWidth: '100%', height: 'auto' }}
                                bgColor={'#ffffff'}
                                fgColor={'#000000'}
                                level={'Q'}
                            />
                        </div>
                    </div>
                    <div className={'modal-footer'}>
                        <button className={'btn btn-general-red'} onClick={closeModal}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className={'modal-backdrop show d-block'}></div>
        </>
    )
}
