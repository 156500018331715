import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useGetCurrentUser } from '../custom-hooks';
import * as Pages from '../components';

export const MainRouter = () => {
    const location = useLocation();
    const user = useGetCurrentUser();

    return (
        <Routes>
            <Route path={'/'} element={ <Pages.HomePage /> } />
            <Route path={'/acceso'} element={<Pages.LoginPage />} />
            <Route path={'/buscador'} element={ <Pages.HTHSearchPage /> } />
            <Route path={'/edificio/:congregation/:territoryNumber/:block/:face/:streetNumber'} element={<Pages.HTHBuildingPage />} />
            <Route path={'/instalar'} element={ <Pages.InstallPage /> } />
            <Route path={'/nuevo-usuario'} element={ <Pages.NewUserPage /> } />
            <Route path={'/privacidad'} element={ <Pages.PrivacyPolicyPage /> } />
            <Route path={'/recovery'} element={ <Pages.RecoveryPage /> } />
            <Route path={'/selector'} element={ <Pages.HTHFreeSelectorPage /> } />
            <Route path={'/servicio'} element={ <Pages.TermsOfServicePage /> } />
            <Route path={'/sobre-nosotros'} element={ <Pages.AboutUsPage /> } />
            {user.isAuth &&
                <>
                    <Route path={'/carritos-autorregistro'} element={<Pages.CartsSelfRegistrationPage />} />
                    <Route path={'/casa-en-casa'} element={<Pages.HTHSelectorPage />} />
                    <Route path={'/casa-en-casa/:territoryNumber'} element={<Pages.HouseToHousePage />} />
                    <Route path={'/novedades'} element={<Pages.NewsPage />} />
                    <Route path={'/tablero'} element={<Pages.BoardPage />} />
                    <Route path={'/tablero/:item'} element={<Pages.BoardPage />} />
                    <Route path={'/telefonica'} element={<Pages.TelephonicSelectorPage />} />
                    <Route path={'/telefonica/:territoryNumber'} element={<Pages.TelephonicPage />} />
                    <Route path={'/usuario'} element={<Pages.MyUserPage /> } />
                </>
            }
            {user.isAdmin && <>
                <Route path={'/admins/config'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/notificaciones'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/usuarios'} element={<Pages.AdminsPage />} />
                <Route path={'/gmail'} element={<Pages.GmailTokensPage />} />
            </>}
            {user.isHthAdmin &&
                <Route path={'/admins/casa-en-casa'} element={<Pages.AdminsPage />} />
            }
            {user.isTelephonicAdmin &&
                <Route path={'/admins/telefonica'} element={<Pages.AdminsPage />} />
            }
            {user.isBoardAdmin && <>
                <Route path={'/admins/asistencia'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/av-y-acomodadores'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/grupos'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/informes'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/reuniones-entresemana'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/reuniones-findesemana'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/salidas'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/tablero'} element={<Pages.AdminsPage />} />
            </>}
            {user.isCartsAdmin && <>
                <Route path={'/admins/carritos'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/carritos/:section'} element={<Pages.AdminsPage />} />
            </>}
            {(user.isHthAdmin || user.isTelephonicAdmin || user.isCartsAdmin || user.isBoardAdmin) && <>
                <Route path={'/admins'} element={<Pages.AdminsPage />} />
                <Route path={'/admins/logs'} element={<Pages.AdminsPage />} />
            </>}
            <Route path={'/*'} element={<Navigate to={`/acceso?url=${location.pathname}`} replace />} />
        </Routes>
    )
}
