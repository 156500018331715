import { Container, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { FC, useMemo, useState } from 'react';
import { isValidPhoneNumber } from '@monorepo/helpers';
import { Loading } from '../../../_commons';
import { setUserPersonalDataService } from '../../../../services';
import { setValuesAndOpenAlertModalReducer } from '../../../../store';
import { typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile } from '../../../../custom-hooks';

type propsType = {
    closeModal: () => void;
    currentUser: typeUser;
}

export const UsersPersonalDataModal: FC<propsType> = ({ closeModal, currentUser }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lastName, setLastName] = useState(currentUser.lastName ?? '');
    const [name, setName] = useState(currentUser.name ?? '');
    const [phoneNumber, setPhoneNumber] = useState(currentUser.phoneNumber || '+54911');
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const isValid: boolean = useMemo(() =>
        lastName.length < 16
        && name.length < 16
        && (phoneNumber === '' || phoneNumber.includes('***') || isValidPhoneNumber(phoneNumber) )
        && (lastName !== (currentUser.lastName ?? '') || name !== (currentUser.name ?? '') || (phoneNumber !== '+54911' && phoneNumber !== (currentUser.phoneNumber ?? '')))
    , [currentUser, lastName, name, phoneNumber]);

    const save = () => {
        if (!isValid) return;
        setErrorMessage('');
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Confirmar',
            message: `Se guardarán los datos del usuario ${currentUser.email}: nombre ${name || '(vacío)'}, apellido ${lastName || '(vacío)'} y teléfono ${!!phoneNumber && phoneNumber !== '+54911' ? phoneNumber : '(vacío)'}`,
            mode: 'confirm',
            animation: 3,
            execution: async () => {
                setIsLoading(true);
                const response = await setUserPersonalDataService(currentUser.id, currentUser.email, name, lastName, phoneNumber && phoneNumber !== '+54911' ? phoneNumber : '');
                setIsLoading(false);
                if (response?.success) {
                    closeModal();
                } else if (!response) {
                    setErrorMessage('No hubo conexión con el servidor');
                } else if (response.alreadyExists) {
                    setErrorMessage('Este teléfono ya está siendo usado por un usuario de la aplicación y no pueden repetirse');
                } else {
                    setErrorMessage('Algo salió mal. Mirar los logs.');
                }
            }
        }));
    }

    return (
        <Modal show={true}
            fullscreen={'md-down'}
            onHide={closeModal}
            size={'lg'}
        >
            <Modal.Header className={isDarkMode ? 'bg-dark text-white' : ''} closeButton>
                <Modal.Title>Datos personales de {currentUser.email}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark' : ''}>
                <Container style={{ maxWidth: '500px', padding: isMobile ? '35px 30px 0' : '35px 0 0' }}>

                    <FloatingLabel className={'mb-3 text-dark'}
                        label={'Nombre'}
                    >
                        <Form.Control type={'text'}
                            className={'form-control'}
                            placeholder={''}
                            value={name}
                            onChange={e => e.target.value.length < 16 ? setName(e.target.value) : null}
                            autoFocus
                        />
                    </FloatingLabel>

                    <FloatingLabel className={'mb-3 text-dark'}
                        label={'Apellido'}
                    >
                        <Form.Control type={'text'}
                            className={'form-control'}
                            placeholder={''}
                            value={lastName}
                            onChange={e => e.target.value.length < 16 ? setLastName(e.target.value) : null}
                            onKeyDown={e => e.key === 'Enter' ? save() : null}
                        />
                    </FloatingLabel>

                    <FloatingLabel
                        className={'mb-3 text-dark'}
                        label={"Teléfono celular"}
                    >
                        <Form.Control
                            type={'text'}
                            className={'form-control'}
                            placeholder={""}
                            value={phoneNumber}
                            onChange={e => e.target.value.includes('***') ? setPhoneNumber('') :
                                e.target.value.length < 16 ? setPhoneNumber(e.target.value)
                                :
                                null
                            }
                            onKeyDown={e => e.key === 'Enter' ? save() : null}
                        />
                    </FloatingLabel>

                    <div>
                        Para que funcionen las notificaciones automáticas el formato de los números debe ser rigurosamente correcto según E.164: sin espacios, comenzando con signo + y con código de país, de tipo y de área (para Buenos Aires +54911 y 8 números más)
                    </div>

                    {isLoading ?
                        <Loading mt={'40px'} mb={'30px'} white={isDarkMode} />
                        :
                        <>
                        {errorMessage &&
                            <div className={'bg-danger text-white p-2 mt-2'}>
                                {errorMessage}
                            </div>
                        }

                        <button className={'btn btn-general-blue d-block w-100 mt-4'}
                            style={{ fontWeight: 'bolder', height: '50px' }}
                            onClick={save}
                            disabled={!isValid}
                        >
                            GUARDAR
                        </button>

                        <button className={`btn btn-general-red d-block w-100 mt-2 mb-3`}
                            style={{ fontWeight: 'bolder', height: '50px' }}
                            onClick={closeModal}
                        >
                            CANCELAR
                        </button>
                        </>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
