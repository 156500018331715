import { addBoardItemService } from '../../../../services';
import { boardItemTypes, typeBoardItem, typeBoardItemType, typeBoardSection } from '@monorepo/models';
import { BoardUploadFile } from './BoardUploadFile';
import { Container, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../../store';
import { Loading } from '../../../_commons';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetShowingLoadingModal } from '../../../../custom-hooks';

type propsType = {
    closeModal: () => void;
    section: typeBoardSection;
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardSectionsItemsAddModal: FC<propsType> = ({ closeModal, section, setCounterReload }) => {
    const [content, setContent] = useState('');
    const [item, setItem] = useState<typeBoardItem | null>(null);
    const [title, setTitle] = useState('');
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const showingLoadingModal = useGetShowingLoadingModal();

    const isValid: boolean = useMemo(() =>
        !!item
        && !showingLoadingModal
        && (item.type !== 'text' || (title.length > 4 && content.length > 10))
    , [content, item, showingLoadingModal, title]);

    const save = async () => {
        if (!isValid || !item) return;
        dispatch(showLoadingModalReducer());
        const success = await addBoardItemService(section.id, section.title, item.type, title, content, null);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
            closeModal();
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo guardar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    return (
        <Modal show={true}
            fullscreen={'md-down'}
            onHide={closeModal}
            size={'lg'}
        >
            <Modal.Header className={isDarkMode ? 'bg-dark text-white' : ''} closeButton>
                <Modal.Title>Agregar ítem a la sección '{section.title}'</Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark' : ''}>
                <Container className={'pb-3'} style={{ maxWidth: '700px' }}>
                    <select className={'form-select mt-3 mb-5'}
                        value={item?.type ?? ''}
                        onChange={e => {
                            setItem({
                                id: 0,
                                order: 0,
                                type: e.target.value as typeBoardItemType
                            });
                            setTitle('');
                            setContent('');
                        }}
                    >
                        <option value={''} disabled>Seleccionar Tipo</option>
                        {boardItemTypes.sort((t1, t2) => t1.label.localeCompare(t2.label)).map(t =>
                            <option key={t.type} value={t.type}>{t.label}</option>
                        )}
                    </select>
                    {item?.type === 'text' &&
                        <>
                        <FloatingLabel className={'text-dark mb-3'}
                            label={'Título'}
                        >
                            <Form.Control type={'text'}
                                className={'form-control'}
                                placeholder={''}
                                value={title}
                                onChange={e => e.target.value.length < 41 ? setTitle(e.target.value) : null}
                                autoFocus
                            />
                        </FloatingLabel>
                        <textarea className={'form-control'}
                            placeholder={'Contenido'}
                            value={content}
                            onChange={e => e.target.value.length < 1001 ? setContent(e.target.value) : null}
                        />
                        </>
                    }

                    {showingLoadingModal ?
                        <Loading mt={'40px'} mb={'30px'} white={isDarkMode} />
                        :
                        <>
                        {item?.type === 'file' ?
                            <BoardUploadFile
                                closeModal={closeModal}
                                section={section}
                                setCounterReload={setCounterReload}
                            />
                            :
                            <div className={'text-center'}>
                                <button className={'btn btn-general-blue d-block w-100 mt-4 mx-auto'}
                                    style={{ fontWeight: 'bolder', height: '50px', maxWidth: '350px' }}
                                    onClick={save}
                                    disabled={!isValid}
                                >
                                    GUARDAR
                                </button>
                                <button className={`btn btn-general-red d-block w-100 mt-2 mb-3 mx-auto`}
                                    style={{ fontWeight: 'bolder', height: '50px', maxWidth: '350px' }}
                                    onClick={closeModal}
                                >
                                    CANCELAR
                                </button>
                            </div>
                        }
                        </>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
