import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { FC, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { typeMeetingAttendance } from '@monorepo/models';

Chart.register(
    CategoryScale,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
);

type propsType = {
    attendances: typeMeetingAttendance[];
}

export const MeetingAttendanceStatistics: FC<propsType> = ({ attendances }) => {
    const monthlyWeekdayData: Record<string, number> = {};
    const monthlyWeekendData: Record<string, number> = {};
    const monthlyWeekdayCount: Record<string, number> = {};
    const monthlyWeekendCount: Record<string, number> = {};
    const yearlyWeekdayData: Record<string, number> = {};
    const yearlyWeekendData: Record<string, number> = {};
    const yearlyWeekdayCount: Record<string, number> = {};
    const yearlyWeekendCount: Record<string, number> = {};
    let averageMonthlyWeekdayData: Record<string, number>;
    let averageMonthlyWeekendData: Record<string, number>;
    let averageYearlyWeekdayData: Record<string, number>;
    let averageYearlyWeekendData: Record<string, number>;
    let chartData;

    useEffect(() => {
        attendances.forEach(entry => {
            // const date = new Date(entry.date);
            // const month = entry.date.substring(0, 7); // YYYY-MM
            // const year = entry.date.substring(0, 4);
            const date = new Date();
            const month = '2024-10';
            const year = 2024;
            const dayOfWeek = date.getDay();
            if (!monthlyWeekdayData[month]) monthlyWeekdayData[month] = 0;
            if (!monthlyWeekendData[month]) monthlyWeekendData[month] = 0;
            if (!monthlyWeekdayCount[month]) monthlyWeekdayCount[month] = 0;
            if (!monthlyWeekendCount[month]) monthlyWeekendCount[month] = 0;
            if (!yearlyWeekdayData[year]) yearlyWeekdayData[year] = 0;
            if (!yearlyWeekendData[year]) yearlyWeekendData[year] = 0;
            if (!yearlyWeekdayCount[year]) yearlyWeekdayCount[year] = 0;
            if (!yearlyWeekendCount[year]) yearlyWeekendCount[year] = 0;
            if (dayOfWeek === 0 || dayOfWeek === 6) {
                monthlyWeekendData[month] += entry.inPerson + entry.zoom + entry.youtube;
                monthlyWeekendCount[month] += 1;
                yearlyWeekendData[year] += entry.inPerson + entry.zoom + entry.youtube;
                yearlyWeekendCount[year] += 1;
            } else { // weekday
                monthlyWeekdayData[month] += entry.inPerson + entry.zoom + entry.youtube;
                monthlyWeekdayCount[month] += 1;
                yearlyWeekdayData[year] += entry.inPerson + entry.zoom + entry.youtube;
                yearlyWeekdayCount[year] += 1;
            }
        });
        averageMonthlyWeekdayData = Object.keys(monthlyWeekdayData).reduce((acc: Record<string, number>, month) => {
            acc[month] = monthlyWeekdayCount[month] ? monthlyWeekdayData[month] / monthlyWeekdayCount[month] : 0;
            return acc;
        }, {});
        averageMonthlyWeekendData = Object.keys(monthlyWeekendData).reduce((acc: Record<string, number>, month) => {
            acc[month] = monthlyWeekendCount[month] ? monthlyWeekendData[month] / monthlyWeekendCount[month] : 0;
            return acc;
        }, {});
        averageYearlyWeekdayData = Object.keys(yearlyWeekdayData).reduce((acc: Record<string, number>, year) => {
            acc[year] = yearlyWeekdayCount[year] ? yearlyWeekdayData[year] / yearlyWeekdayCount[year] : 0;
            return acc;
        }, {});
        averageYearlyWeekendData = Object.keys(yearlyWeekendData).reduce((acc: Record<string, number>, year) => {
            acc[year] = yearlyWeekendCount[year] ? yearlyWeekendData[year] / yearlyWeekendCount[year] : 0;
            return acc;
        }, {});
        chartData = {
            labels: Object.keys(averageMonthlyWeekdayData),
            datasets: [
                {
                    borderColor: 'rgba(75, 192, 192, 1)',
                    data: Object.values(averageMonthlyWeekdayData),
                    fill: false,
                    label: 'Promedio Asistencias Semanales',
                    pointHoverRadius: 7,
                    pointRadius: 5,
                    tension: 0.4
                },
                {
                    borderColor: 'rgba(255, 99, 132, 1)',
                    data: Object.values(averageMonthlyWeekendData),
                    fill: false,
                    label: 'Promedio Asistencias Fin de Semana',
                    pointHoverRadius: 7,
                    pointRadius: 5,
                    tension: 0.4
                },
                {
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderDash: [5, 5],
                    data: Object.values(averageYearlyWeekdayData),
                    fill: false,
                    label: 'Promedio Anual Asistencias Semanales',
                    tension: 0.1
                },
                {
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderDash: [5, 5],
                    data: Object.values(averageYearlyWeekendData),
                    fill: false,
                    label: 'Promedio Anual Asistencias Fin de Semana',
                    tension: 0.1
                }
            ]
        };
    }, []);

    return (
        <div className='container my-4'>
            <h3 className="text-center">Estadísticas</h3>
            <div style={{ width: '80%', maxWidth: '600px', height: '400px', margin: '0 auto' }}>
                {chartData &&
                    <Line data={chartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: true,
                                    position: 'top'
                                },
                                tooltip: {
                                    backgroundColor: 'rgba(0,0,0,0.7)'
                                }
                            },
                            scales: {
                                x: {
                                    grid: {
                                        color: 'rgba(0, 0, 0, 0.1)'
                                    }
                                },
                                y: {
                                    grid: {
                                        color: 'rgba(0, 0, 0, 0.1)'
                                    }
                                }
                            }
                        }}
                    />
                }
            </div>
        </div>
    )
}
