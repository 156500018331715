import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { BoardSection } from './subcomponents/BoardSection';
import { BoardSideBar } from './subcomponents/BoardSideBar';
import { getBoardSectionsService, normalizeString } from '../../services';
import { H2, Hr, Loading } from '../_commons';
import { pdfjs } from 'react-pdf';
import { typeBoardSection } from '@monorepo/models';
import { useEffect, useMemo, useState } from 'react';
import { useGetIsMobile } from '../../custom-hooks';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

export const BoardPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [sections, setSections] = useState<typeBoardSection[] | null>(null);
    const isMobile = useGetIsMobile();

    const currentSection: typeBoardSection | null = useMemo(() => {
        const relativePath = window.location.pathname;
        const _currentSection = sections?.find(i => `/tablero/${normalizeString(i.title)}` === relativePath) ?? null;
        return _currentSection;
    // eslint-disable-next-line
    }, [sections, window.location.pathname]);

    useEffect(() => {
        getBoardSectionsService().then(_boardSections => {
            setIsLoading(false);
            if (_boardSections) setSections(_boardSections);
        });
    }, []);

    if (isLoading) return <Loading mt={'120px'} />;

    if (!sections) return <div className={'mt-5'}>No se pudieron recuperar los datos</div>;

    if (!sections.length) return <div className={'mt-5'}>Aun no se crearon secciones</div>;

    return (
        <div className={'row w-100 mx-auto'}>
            <div className={`col-lg-2 p-0 ${isMobile ? 'mt-3 w-75 mx-auto text-center' : ''}`}
                style={{ marginTop: '80px' }}
            >
                <BoardSideBar
                    currentSection={currentSection}
                    sections={sections}
                />
            </div>

            {isMobile && <Hr classes={'mt-3 mb-0'} />}

            <div className={'col-lg-10'}>
                {!isMobile && <Hr styles={{ marginTop: '80px' }} />}

                {!currentSection || currentSection?.title === '' ?
                    <H2 title={'TABLERO'} mt={'30px'} />
                    :
                    <>
                    <H2 title={currentSection.title.toUpperCase()} mt={'30px'} />
                    <BoardSection section={currentSection} />
                    </>
                }
            </div>
        </div>
    )
}
