import { H2 } from '../../_commons';
import { MeetingAttendanceForm } from './subcomponents/MeetingAttendanceFom';
import { MeetingAttendanceStatistics } from './subcomponents/MeetingAttendanceStatistics';
import { meetingServices } from 'src/services/meetingServices';
import { typeMeetingAttendance } from '@monorepo/models';
import { useEffect, useState } from 'react';

export const AdminsMeetingAttendance = () => {
    const [attendances, setAttendances] = useState<typeMeetingAttendance[]>([]);

    useEffect(() => {
        meetingServices.Get().then(_meetingDoc => {
            if (!_meetingDoc) return;
            setAttendances([]);
        });
        // const fetchData = async () => {
        //     try {
        //     const querySnapshot = await getDocs(collection(db, 'asistencias'));
        //     const asistenciasData = querySnapshot.docs.map((doc) => ({
        //         ...doc.data(),
        //         id: doc.id,
        //     }));
        //     asistenciasData.sort((a, b) => new Date(b.date) - new Date(a.date));
        //     setHistorial(asistenciasData);
        //     // Extraer meses y años únicos de las fechas
        //     const uniqueMonthsYears = asistenciasData.reduce((acc, entry) => {
        //         const date = new Date(entry.date);
        //         const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`; // 'mes-año'
        //         if (!acc.includes(monthYear)) {
        //         acc.push(monthYear);
        //         }
        //         return acc;
        //     }, []);
        //     setAvailableMonths(uniqueMonthsYears);
        //     } catch (error) {
        //         console.error('Error al obtener los documentos: ', error);
        //         showToastMessage('Error al obtener los documentos.', 'error');
        //     }
        // };
        // fetchData();
    }, []);

    return (
        <>
        <H2 title={'ASISTENCIA A LAS REUNIONES'} />
        <MeetingAttendanceForm attendances={attendances} />
        <MeetingAttendanceStatistics attendances={attendances} />
        </>
    )
}
