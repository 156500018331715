import { FC } from 'react';

type propsType = {
    closeModal: () => void;
    toastMessage: string;
    toastType: string;
}

export const MeetingAttendanceFormToast: FC<propsType> = ({ closeModal, toastMessage, toastType }) => {
    return (
        <div className={'toast position-fixed show bottom-0 end-0 m-3'}
            style={{ zIndex: 1 }}
            role={'alert'}
            aria-live={'assertive'}
            aria-atomic={'true'}
        >
            <div className={`toast-header ${toastType === 'success' ? 'bg-success text-white' : 'bg-danger text-white'}`}>
                <strong className={'me-auto'}>
                    {toastType === 'success' ? 'Éxito' : 'Error'}
                </strong>
                <button type={'button'}
                    className={'btn-close'}
                    onClick={closeModal}
                ></button>
            </div>
            <div className={'toast-body'}>{toastMessage}</div>
        </div>
    )
}
