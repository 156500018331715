import { FC, useEffect, useState } from 'react';
import { H2, Hr, Loading } from '../../_commons';
import { timeConverter } from '../../../services';
import { typeLocalTelephonicStatistic, typeResetDate, typeTelephonicTerritory } from '@monorepo/models';
import { useGetIsDarkMode, useGetIsMobile } from '../../../custom-hooks';

type propsType = {
    telephonicTerritory: typeTelephonicTerritory;
}

export const LocalStatistics: FC<propsType> = ({ telephonicTerritory }) => {
    const [localS, setLocalStatistics] = useState<typeLocalTelephonicStatistic>();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    useEffect(() => {
        if (!telephonicTerritory.households.length) return;
        const statistics0: typeLocalTelephonicStatistic = {
            congregation: telephonicTerritory.congregation,
            isFinished: telephonicTerritory.stateOfTerritory.isFinished,
            numberOfHouseholds: telephonicTerritory.households.length,
            numberOf_Contesto: telephonicTerritory.households.filter(x => x.callingState === 'Contestó' && !x.notSubscribed).length,
            numberOf_NoContesto: telephonicTerritory.households.filter(x => x.callingState === 'No contestó' && !x.notSubscribed).length,
            numberOf_ADejarCarta: telephonicTerritory.households.filter(x => x.callingState === 'A dejar carta' && !x.notSubscribed).length,
            numberOf_NoLlamar: telephonicTerritory.households.filter(x => x.callingState === 'No llamar' && !x.notSubscribed).length,
            numberOf_NoAbonado: telephonicTerritory.households.filter(x => x.notSubscribed).length,
            numberOf_FreePhones: telephonicTerritory.households.filter(x => x.callingState === 'No predicado' && !x.notSubscribed).length,
            stateOfTerritory: telephonicTerritory.stateOfTerritory,
            territoryNumber: telephonicTerritory.territoryNumber,
            numberOf_ADejarCarta_relative: 0,
            numberOf_NoLlamar_relative: 0,
            numberOfAlreadyCalled: 0,
            numberOfAlreadyCalledRelative: 0,
            numberOfAlreadyDone: 0,
            numberOfAlreadyDoneRelative: 0,
            numberOf_NoContesto_relative: 0
        };
        statistics0.numberOfAlreadyCalled = statistics0.numberOf_Contesto + statistics0.numberOf_NoContesto + statistics0.numberOf_NoLlamar + statistics0.numberOf_ADejarCarta + statistics0.numberOf_NoAbonado;
        statistics0.numberOfAlreadyCalledRelative = Math.round(statistics0.numberOfAlreadyCalled * 100/statistics0.numberOfHouseholds);
        statistics0.numberOfAlreadyDone = statistics0.numberOf_Contesto + statistics0.numberOf_NoLlamar + statistics0.numberOf_ADejarCarta;
        statistics0.numberOf_FreePhones_relative = Math.round((statistics0.numberOf_FreePhones * 100)/statistics0.numberOfHouseholds);
        setLocalStatistics(statistics0);
    }, [telephonicTerritory]);
    
    if (!localS) {
        return <Loading mt={'40px'} />;
    }
    
    return (
    <>
        <H2 title={"ESTADÍSTICAS"} />

        <div style={{ margin: isMobile ? '0' : '0 10%' }}>

            <br/>

            <div className={`card px-5 py-3 ${isMobile ? 'text-center' : ''} ${isDarkMode ? 'bg-dark text-white' : ''}`}>

                <h1 className={'text-center mt-3'}> Generales </h1>

                <br/>

                <h4> Hay {localS.numberOfHouseholds} viviendas: {localS.numberOfHouseholds - localS.numberOf_NoAbonado} abonadas y {localS.numberOf_NoAbonado} no abonadas </h4>

                <br/>

                <h4> Libres para llamar: {localS.numberOf_FreePhones} <span style={{ fontSize: 21 }}>({localS.numberOf_FreePhones_relative}%)</span> </h4>

                <br/>

                <h4> Llamadas: {localS.numberOfAlreadyCalled} <span style={{ fontSize: 21 }}>({localS.numberOfAlreadyCalledRelative}%)</span></h4>

                <br/>

                <Hr />

                <h3 className={'text-center mt-3'}> Composición de Llamadas ({localS.numberOfAlreadyCalled}) </h3>

                <br/>

                <h4> No abonados: {localS.numberOf_NoAbonado} viviendas </h4>

                <br/>

                <h4> No contestó: {localS.numberOf_NoContesto} viviendas </h4>
                
                <br/>

                <h4 className={'mb-3'}> Predicadas: {localS.numberOfAlreadyDone} viviendas </h4>

                <h4> &nbsp;&nbsp; -Contestó: {localS.numberOf_Contesto} viviendas </h4>

                <h4> &nbsp;&nbsp; -A dejar carta: {localS.numberOf_ADejarCarta} viviendas </h4>

                <h4> &nbsp;&nbsp; -No llamar: {localS.numberOf_NoLlamar} viviendas </h4>

                <br/>

                {!!telephonicTerritory.stateOfTerritory.resetDates.length &&
                    <div className={'my-4'}>
                        <Hr />
                        <h3 className={'text-center my-4'}> Reseteos del territorio {telephonicTerritory.territoryNumber} </h3>
                        {telephonicTerritory.stateOfTerritory.resetDates.map((reset: typeResetDate, index: number) =>
                            <h4 key={index}>
                                &nbsp; -El {timeConverter(reset.date)} con la opción {reset.option}
                            </h4>
                        )}
                    </div>
                }
            </div>

        </div>
    </>
    )
}
