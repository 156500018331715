import { typeCartAssignment, typeCartParticipant } from '@monorepo/models';

export const getPriorityPoints = (participant: typeCartParticipant, cartAssignments: typeCartAssignment[], month: number, year: number): number => {
    let priorityPoints = 0;
    if (participant.priority === 1) priorityPoints += 60;
    if (participant.priority === 2) priorityPoints += 30;
    if (participant.hasParticipatedBefore) priorityPoints += 50;
    participant.availabilities.forEach(() => {
        priorityPoints -= 20;
    });
    cartAssignments.filter(a => a.participants.some(p => p.userId === participant.userId) && a.year === year && a.month === month).forEach(() => {
        priorityPoints -= 50;
    });
    return priorityPoints;
}

export const getTimeFromPeriodData = (startHour: number, startMinutes: number, endHour: number, endMinutes: number): string => (
    `${startHour.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}-${endHour.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`
);

export const normalizeString = (value: string): string => `${value.toLowerCase()
    .replace(/\s+/g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ñ/g, 'n')}`
;
