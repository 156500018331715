import { BsArrowBarDown, BsArrowBarUp } from 'react-icons/bs';
import { Card } from 'react-bootstrap';
import { changePswOtherUserService, deleteUserService, editUserService } from '../../../../services/userServices';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Hr } from '../../../_commons';
import { setValuesAndOpenAlertModalReducer, typeMode } from '../../../../store';
import { typeRole, typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from '../../../../custom-hooks';
import { UsersPersonalDataModal } from './UsersPersonalDataModal';

type propsType = {
    currentUser: typeUser;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const UsersCard: FC<propsType> = ({ currentUser, setIsLoading }) => {
    const [showPersonalDataModal, setShowPersonalDataModal] = useState(false);
    const [showCardBody, setShowCardBody] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    const openAlertModalHandler = (mode: typeMode, title: string, message: string, animation?: number, execution?: Function): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode,
            title,
            message,
            execution,
            animation
        }));
    }

    const openResetPasswordConfirmModalHandler = (): void => {
        openAlertModalHandler(
            'confirm',
            '¿Resetear clave?',
            `Esto reseteará la contraseña del usuario ${currentUser.email}, cerrará su sesión si está abierta y le enviará un correo con la nueva contraseña`,
            undefined,
            async () => {
                setIsLoading(true);
                const response: [string, boolean]|null = await changePswOtherUserService(currentUser.email);
                setIsLoading(false);
                if (!response || !response[0])
                    openAlertModalHandler('alert', 'Error', `Algo falló al resetear la contraseña de ${currentUser.email}`, 2);
                else if (response[1])
                    openAlertModalHandler('alert', `Clave reseteada y enviada por email a ${currentUser.email}`, `Nueva clave: ${response[0]}`, 1);
                else
                    openAlertModalHandler('alert', 'Se reseteó la contraseña pero falló el envío del email', `Nueva clave: ${response[0]}`);
            }
        );
    }

    const openDeleteUserConfirmModalHandler = (): void => {
        openAlertModalHandler(
            'confirm',
            '¿Eliminar este usuario?',
            `Se eliminará el usuario ${currentUser.email} definitivamente`,
            undefined,
            async () => {
                setIsLoading(true);
                deleteUserService(currentUser.id).then((success: boolean) => {
                    setIsLoading(false);
                    if (!success) {
                        openAlertModalHandler(
                            'alert',
                            'Algo falló',
                            `No se pudo eliminar al usuario ${currentUser.email}`,
                            2
                        );
                        return;
                    }
                    openAlertModalHandler(
                        'alert',
                        'Usuario eliminado',
                        `Se eliminó al usuario ${currentUser.email}`,
                        1
                    );
                    window.location.reload();
                });
            }
        );
    }

    const editUserHandler = async (email: string, isActive: boolean, roles: typeRole[]): Promise<void> => {
        setIsLoading(true);
        const updatedUser: typeUser|null = await editUserService(email, isActive, roles);
        setIsLoading(false);
        if (!updatedUser) return openAlertModalHandler('alert', 'Error', 'Algo falló al modificar usuario', 2);
    }

    return (
        <Card key={currentUser.email}
            className={isDarkMode ? 'bg-dark text-white' : ''}
            style={{
                backgroundColor: '#f6f6f8',
                margin: '60px auto 0 auto',
                width: isMobile ? '95%': '500px'
            }}
        >
            {showPersonalDataModal &&
                <UsersPersonalDataModal
                    closeModal={() => setShowPersonalDataModal(false)}
                    currentUser={currentUser}
                />
            }

            <Card.Body style={{ padding: `30px 30px ${showCardBody ? '30px' : '12px'} 30px` }}>

                <Card.Title className={'pointer text-center'}
                    style={{
                        padding: '20px',
                        fontSize: isMobile ? '1.3rem' : '1.8rem'
                    }}
                    onClick={() => setShowCardBody(x => !x)}
                >
                    <div>{currentUser.name} {currentUser.lastName}</div>
                    <div>{currentUser.email}</div>
                    {showCardBody ?
                        <BsArrowBarUp size={isMobile ? '2rem' : '2.4rem'} className={'mt-3'} />
                        :
                        <BsArrowBarDown size={isMobile ? '2rem' : '2.4rem'} className={'mt-3'} />
                    }
                </Card.Title>

                {showCardBody && <>

                    {/* <Card.Text className={'text-center mt-3'} style={{ fontSize: '1.2rem', fontWeight: 600 }}>
                        Grupo: {currentUser.group} &nbsp;&nbsp;
                        <button className={'btn btn-general-blue'} onClick={() => setGroupVisible(!groupVisible)}>
                            CAMBIAR GRUPO
                        </button>
                    </Card.Text> */}

                    {/* {groupVisible &&
                        <div className={'mx-auto my-4'}>
                            {isMobile ? <>
                                <Pagination className={'text-center d-flex justify-content-center mb-0'} size={'lg'}>
                                    {[1,2,3].map(groupNumber =>
                                        <Pagination.Item key={groupNumber} className={''}
                                            active={groupNumber === currentUser.group}
                                            onClick={() => {
                                                editUserHandler(currentUser.email, currentUser.isActive, currentUser.role, groupNumber)
                                            }}
                                        >
                                            {groupNumber}
                                        </Pagination.Item>
                                    )}
                                </Pagination>
                                <Pagination className={'text-center d-flex justify-content-center'} size={'lg'}>
                                    {[4,5,6].map(groupNumber =>
                                        <Pagination.Item key={groupNumber} className={''}
                                            active={groupNumber === currentUser.group}
                                            onClick={() => {
                                                editUserHandler(currentUser.email, currentUser.isActive, currentUser.role, groupNumber)
                                            }}
                                        >
                                            {groupNumber}
                                        </Pagination.Item>
                                    )}
                                </Pagination>
                            </>
                            :
                                <Pagination className={'text-center d-flex justify-content-center'} size={'lg'}>
                                    {userGroups.map(groupNumber =>
                                        <Pagination.Item key={groupNumber} className={''}
                                            active={groupNumber === currentUser.group}
                                            onClick={() => {
                                                editUserHandler(currentUser.email, currentUser.isActive, currentUser.role, groupNumber)
                                            }}
                                        >
                                            {groupNumber}
                                        </Pagination.Item>
                                    )}
                                </Pagination>
                            }
                        </div>
                    } */}
                

                    <Hr />
                
                    {user.isAdmin && <>
                        <button className={`col-12 btn btn ${(currentUser.name || currentUser.lastName || currentUser.phoneNumber) ? 'btn-general-red' : 'btn-general-blue'} my-1`}
                            onClick={() => setShowPersonalDataModal(true)}
                        >
                            {(currentUser.name || currentUser.lastName || currentUser.phoneNumber) ? 'EDITAR' : 'AGREGAR'} DATOS PERSONALES
                        </button>

                        <br/>

                        {user?.email !== currentUser.email &&
                            <button className={'col-12 btn btn-general-red my-1'}
                                onClick = {() => openResetPasswordConfirmModalHandler()}
                            >
                                RESETEAR CONTRASEÑA
                            </button>
                        }

                        <button className={`col-12 btn btn ${currentUser.isActive ? 'btn-general-red' : 'btn-general-blue'} my-1`}
                            onClick={() => editUserHandler(currentUser.email, !currentUser.isActive, currentUser.roles)}
                        >
                            {currentUser.isActive ? 'DESACTIVAR' : 'ACTIVAR'}
                        </button>

                        <button className={'col-12 btn btn-general-red my-1'}
                            disabled={
                                !!currentUser.isActive ||
                                !!currentUser.roles.length ||
                                !!currentUser.hthAssignments?.length ||
                                !!currentUser.phoneAssignments?.length ||
                                !!currentUser.cartAssignments?.length
                            }
                            onClick = {openDeleteUserConfirmModalHandler}
                        >
                            ELIMINAR USUARIO
                        </button>

                        <Hr />

                        <button className={`col-12 btn ${currentUser.roles.includes(1) ? 'btn-general-red' : 'btn-general-blue'} my-1`}
                            onClick = {() => editUserHandler(currentUser.email, currentUser.isActive, currentUser.roles.includes(1) ? currentUser.roles.filter(r => r !== 1) : [1])}
                            >
                            {currentUser.roles.includes(1) ? 'QUITAR COMO ADMIN GENERAL' : 'HACER ADMIN GENERAL'}
                        </button>
                        <br />
                        <button className={`col-12 btn ${currentUser.roles.includes(2) ? 'btn-general-red' : 'btn-general-blue'} my-1`}
                            onClick = {() => editUserHandler(currentUser.email, currentUser.isActive, currentUser.roles.includes(2) ? currentUser.roles.filter(r => r !== 2) : [...currentUser.roles.filter(r => r !== 1 && r !== 5), 2])}
                            disabled={currentUser.roles.includes(1)}
                        >
                            {currentUser.roles.includes(2) ? 'QUITAR COMO ADMIN DE CASA EN CASA' : 'HACER ADMIN DE CASA EN CASA'}
                        </button>
                        <button className={`col-12 btn ${currentUser.roles.includes(3) ? 'btn-general-red' : 'btn-general-blue'} my-1`}
                            onClick = {() => editUserHandler(currentUser.email, currentUser.isActive, currentUser.roles.includes(3) ? currentUser.roles.filter(r => r !== 3) : [...currentUser.roles.filter(r => r !== 1), 3])}
                            disabled={currentUser.roles.includes(1)}
                        >
                            {currentUser.roles.includes(3) ? 'QUITAR COMO ADMIN DE TELEFÓNICA' : 'HACER ADMIN DE TELEFÓNICA'}
                        </button>
                        <button className={`col-12 btn ${currentUser.roles.includes(4) ? 'btn-general-red' : 'btn-general-blue'} my-1`}
                            onClick = {() => editUserHandler(currentUser.email, currentUser.isActive, currentUser.roles.includes(4) ? currentUser.roles.filter(r => r !== 4) : [...currentUser.roles.filter(r => r !== 1), 4])}
                            disabled={currentUser.roles.includes(1)}
                        >
                            {currentUser.roles.includes(4) ? 'QUITAR COMO ADMIN DE CARRITOS' : 'HACER ADMIN DE CARRITOS'}
                        </button>
                        <button className={`col-12 btn ${currentUser.roles.includes(5) ? 'btn-general-red' : 'btn-general-blue'} my-1`}
                            onClick = {() => editUserHandler(currentUser.email, currentUser.isActive, currentUser.roles.includes(5) ? currentUser.roles.filter(r => r !== 5) : [...currentUser.roles.filter(r => r !== 1 && r !== 2), 5])}
                            disabled={currentUser.roles.includes(1)}
                        >
                            {currentUser.roles.includes(5) ? 'QUITAR COMO ADMIN DE EDIFICIOS' : 'HACER ADMIN DE EDIFICIOS'}
                        </button>
                        <button className={`col-12 btn ${currentUser.roles.includes(6) ? 'btn-general-red' : 'btn-general-blue'} my-1`}
                            onClick = {() => editUserHandler(currentUser.email, currentUser.isActive, currentUser.roles.includes(6) ? currentUser.roles.filter(r => r !== 6) : [...currentUser.roles.filter(r => r !== 1), 6])}
                            disabled={currentUser.roles.includes(1)}
                        >
                            {currentUser.roles.includes(6) ? 'QUITAR COMO ADMIN DE TABLERO' : 'HACER ADMIN DE TABLERO'}
                        </button>
                    </>}
                </>}
            </Card.Body>
        </Card>
    )
}
