"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unauthenticatedUser = void 0;
///////////////////////////////////////////////////////////////////////////////////////////////////
exports.unauthenticatedUser = {
    cartAssignments: [],
    congregation: 0,
    email: '',
    hthAssignments: [],
    id: 0,
    isActive: false,
    isAdmin: false,
    isAuth: false,
    isBoardAdmin: false,
    isBuildingsAdmin: false,
    isCartsAdmin: false,
    isHthAdmin: false,
    isTelephonicAdmin: false,
    phoneAssignments: [],
    roles: [],
    rolesAsText: ''
};
