import { FaTrashAlt } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import { MeetingAttendanceFomPrint } from './MeetingAttendanceFomPrint';
import { MeetingAttendanceFormConfirm } from './MeetingAttendanceFormConfirm';
import { MeetingAttendanceFormDelete } from './MeetingAttendanceFormDelete';
import { MeetingAttendanceFormToast } from './MeetingAttendanceFormToast';
import { typeMeetingAttendance } from '@monorepo/models';
import { useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    attendances: typeMeetingAttendance[];
}

export const MeetingAttendanceForm: FC<propsType> = ({ attendances }) => {
    const [availableMonths, setAvailableMonths] = useState<string[]>([]);
    const [date, setDate] = useState('');
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [inPerson, setInPerson] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [monthFilter, setMonthFilter] = useState(''); 
    const [notes, setNotes] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [youtube, setYoutube] = useState(0);
    const [zoom, setZoom] = useState(0);
    const isMobile = useGetIsMobile();

    useEffect(() => {
        //     // Extraer meses y años únicos de las fechas
        //     const uniqueMonthsYears = asistenciasData.reduce((acc, entry) => {
        //         const date = new Date(entry.date);
        //         const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`; // 'mes-año'
        //         if (!acc.includes(monthYear)) {
        //         acc.push(monthYear);
        //         }
        //         return acc;
        //     }, []);
        //     setAvailableMonths(uniqueMonthsYears);
    }, []);

    const handleSubmit = async () => {
        if (!date) {
            showToastMessage('La fecha es obligatoria.', 'error');
            return;
        }
        setIsLoading(true);
        const total = inPerson + zoom + youtube;
        try {
            
            setDate('');
            setInPerson(0);
            setZoom(0);
            setYoutube(0);
            setNotes('');
            showToastMessage('¡Asistencia agregada correctamente!', 'success');
        } catch(e) {
            console.error('Error al agregar el documento: ', e);
            showToastMessage('Error al agregar la asistencia.', 'error');
        } finally {
            setIsLoading(false);
            setShowAddModal(false);
        }
    }

    const handleDelete = async () => {
        try {
            // await deleteDoc(doc(db, 'asistencias', deleteId));
            // setHistorial(historial.filter((entry) => entry.id !== deleteId));
            showToastMessage('Asistencia eliminada correctamente.', 'success');
        } catch(e) {
            console.error('Error al eliminar el documento: ', e);
            showToastMessage('Error al eliminar la asistencia.', 'error');
        } finally {
            setShowDeleteModal(false);
        }
    }

    const showToastMessage = (message: string, type: string) => {
        setToastMessage(message);
        setToastType(type);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 5000);
    };

    const filteredHistorial = attendances.filter((entry) => {
        if (!monthFilter) return true;
        // const entryMonth = new Date(entry.date).getMonth() + 1;
        // return entryMonth === parseInt(monthFilter);
    });

    return (
        <div className={'d-flex flex-column justify-content-center align-items-center'}>
            {showAddModal &&
                <MeetingAttendanceFormConfirm
                    closeModal={() => setShowAddModal(false)}
                    date={date}
                    handleSubmit={handleSubmit}
                    inPerson={inPerson}
                    isLoading={isLoading}
                    notes={notes}
                    youtube={youtube}
                    zoom={zoom}
                />
            }
            {showDeleteModal &&
                <MeetingAttendanceFormDelete
                    closeModal={() => setShowDeleteModal(false)}
                    handleDelete={handleDelete}
                />
            }
            {showToast &&
                <MeetingAttendanceFormToast
                    closeModal={() => setShowToast(false)}
                    toastMessage={toastMessage}
                    toastType={toastType}
                />
            }
            <div className={'container px-3'}>
                <form className={'mx-auto w-100 mb-3'}>
                    <div className={'d-flex flex-lg-row flex-column gap-3'}>
                        <div className={'mb-4 row justify-content-center'}>
                            <div className={'col-12'}>
                                <i className={'fas fa-calendar-alt fa-lg me-2'} />
                                <label htmlFor={'fecha'} className={'form-label fw-bold'}>
                                    Fecha
                                </label>
                                <input type={'date'}
                                    id={'fecha'}
                                    className={'form-control'}
                                    value={date}
                                    onChange={e => setDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={'mb-4 row justify-content-center'}>
                            <div className={'col-12'}>
                                <i className={'fa-solid fa-users fa-lg me-2'} />
                                <label htmlFor={'inPerson'} className={'form-label fw-bold'}>
                                    Presencial
                                </label>
                                <input type={'number'}
                                    id={'inPerson'}
                                    className={'form-control'}
                                    placeholder={'0'}
                                    value={inPerson || ''}
                                    onChange={e => setInPerson(parseInt(e.target.value) || 0)}
                                />
                            </div>
                        </div>
                        <div className={'mb-4 row justify-content-center'}>
                            <div className={'col-12'}>
                                <i className={'fas fa-video fa-lg me-2'} />
                                <label htmlFor={'zoom'} className={'form-label fw-bold'}>
                                    Zoom
                                </label>
                                <input type={'number'}
                                    id={'zoom'}
                                    className={'form-control'}
                                    placeholder={'0'}
                                    value={zoom || ''}
                                    onChange={e => setZoom(parseInt(e.target.value) || 0)}
                                />
                            </div>
                        </div>
                        <div className={'mb-4 row justify-content-center'}>
                            <div className={'col-12'}>
                                <i className={'fab fa-youtube fa-lg me-2'} />
                                <label htmlFor={'youtube'} className={'form-label fw-bold'}>
                                    YouTube
                                </label>
                                <input type={'number'}
                                    id={'youtube'}
                                    className={'form-control'}
                                    placeholder={'0'}
                                    value={youtube || ''}
                                    onChange={e => setYoutube(parseInt(e.target.value) || 0)}
                                />
                            </div>
                        </div>
                        <div className={'mb-4 row justify-content-center'}>
                            <div className={'col-12'}>
                                <label htmlFor={'total'} className={'form-label fw-bold'}>
                                    Total
                                </label>
                                <input type={'number'}
                                    id={'total'}
                                    className={'form-control'}
                                    placeholder={'0'}
                                    value={inPerson + zoom + youtube}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'mb-4 row justify-content-center'}>
                        <div className={'col-12'}>
                            <i className={'fas fa-sticky-note fa-lg me-2'} />
                            <label htmlFor={'notas'} className={'form-label fw-bold'}>
                                Notas
                            </label>
                            <textarea id={'notas'}
                                className={'form-control'}
                                rows={isMobile ? 3 : 2}
                                value={notes}
                                onChange={e => e.target.value.length < 100 ? setNotes(e.target.value) : null}
                            />
                        </div>
                    </div>
                    <div className={'mb-4 text-center'}>
                        <button type={'button'}
                            className={'btn btn-general-blue px-4'}
                            onClick={() => setShowAddModal(true)}
                        >
                            Agregar Asistencia
                        </button>
                    </div>
                </form>

                <h3 className={'mb-2 text-center'}>Historial</h3>
                <div className={'table-responsive'}>
                    <table className={'table table-striped table-bordered w-100 mx-auto'}>
                        <thead>
                            <tr className={'text-center'}>
                                <th>Fecha</th>
                                <th>Presencial</th>
                                <th>Zoom</th>
                                <th>YouTube</th>
                                <th>Total</th>
                                <th>Notas</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className={'text-center'}>
                            {attendances.map(entry => {
                                // const duplicate = attendances.filter(e => e.date === entry.date).length > 1;
                                const duplicate = false;
                                return (
                                    <tr key={entry.inPerson     }
                                        style={{ backgroundColor: duplicate ? 'IndianRed' : undefined }}
                                    >
                                        <td>
                                            {/* {new Date(
                                                new Date(entry.date).getTime() +
                                                new Date(entry.date).getTimezoneOffset() * 60000
                                            ).toLocaleDateString('es-ES', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            })} */}
                                        </td>
                                        <td>{entry.inPerson}</td>
                                        <td>{entry.zoom}</td>
                                        <td>{entry.youtube}</td>
                                        <td>{entry.inPerson + entry.zoom + entry.youtube}</td>
                                        <td>{entry.notes}</td>
                                        <td>
                                            <button className={'btn btn-general-red'}
                                                onClick={() => {
                                                    setDeleteId(0);
                                                    setShowDeleteModal(true);
                                                }}
                                            >
                                                <FaTrashAlt />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className={'mx-auto mb-3 w-100'} style={{ maxWidth: '400px' }}> 
                    <div className={'col-12'}>
                        <label htmlFor={'monthFilter'} className={'form-label fw-bold'}>
                            Filtrar por Mes y Año
                        </label>
                        <select id={'monthFilter'}
                            className={'form-control'}
                            value={monthFilter}
                            onChange={e => setMonthFilter(e.target.value)}
                        >
                            <option value=''>Todos los meses</option>
                            {availableMonths.map(monthYear => {
                                const [month, year] = monthYear.split('-');
                                return (
                                    <option key={monthYear} value={monthYear}>
                                        {new Date(parseInt(year), parseInt(month) - 1).toLocaleDateString('es-ES', { month: 'long', year: 'numeric' })}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <MeetingAttendanceFomPrint />
            </div>
        </div>
    )
}
