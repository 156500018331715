import { BsClock, BsTrash } from 'react-icons/bs';
import { CartPointEnabledPeriodAdd } from './CartPointEnabledPeriodAdd';
import { deleteCartEnabledPeriodService } from '../../../../services/cartServices';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { getTimeFromPeriodObject } from '@monorepo/helpers';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../../store';
import { Hr } from '../../../_commons';
import { typeCartPeriod, typeCartPoint } from '@monorepo/models';
import { useDispatch } from 'react-redux';

type propsType = {
    goBack: () => void;
    point: typeCartPoint;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartPointEnabledPeriod: FC<propsType> = ({ goBack, point, setRefreshCounter }) => {
    const [showFormAdd, setShowFormAdd] = useState(false);
    const dispatch = useDispatch();

    const handleDelete = (period: typeCartPeriod) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar el horario ${getTimeFromPeriodObject(period)} hs. del punto '${point.name}' y ya no estará disponible. Esta acción no afecta a las asignaciones ya confirmadas.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteCartEnabledPeriodService(point, period);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    return (
        <div className={'container mt-3'} style={{ maxWidth: '650px' }}>
            <Hr />
            <div className={'mb-3'}>
                <h3 className={'text-center flex-grow-1'}>
                    Listado de Horarios <BsClock className={'ms-1 mb-2'} />
                </h3>
            </div>

            {!showFormAdd &&
                <div className={'row'}>
                    {!!point.enabledPeriods?.length ?
                        (point.enabledPeriods?.sort((p1, p2) => p1.startHour - p2.startHour || p1.startMinutes - p2.startMinutes).map(period =>
                            <div key={period.id}
                                className={'card-footer d-flex gap-4 justify-content-between'}
                            >
                                <div>
                                    <h5 className={'card-title'}>
                                        Horario <span className={'font-monospace small'}>{getTimeFromPeriodObject(period)} hs.</span>
                                    </h5>
                                </div>
                                <button className={'btn btn-general-red btn-sm'}
                                    onClick={() => handleDelete(period)}
                                >
                                    Eliminar <BsTrash />
                                </button>
                            </div>
                        ))
                        :
                        <>No hay ninguno</>
                    }
                </div>
            }

            {showFormAdd ?
                <CartPointEnabledPeriodAdd
                    closeForm={() => setShowFormAdd(false)}
                    point={point}
                    setRefreshCounter={setRefreshCounter}
                />
                :
                <div className={'text-center mt-4 mb-5 d-grid gap-2'}>
                    <button className={'btn btn-general-blue btn-sm py-2'}
                        onClick={() => setShowFormAdd(x => !x)}
                    >
                        Agregar horario
                    </button>
                    <button className={'btn btn-dark btn-sm py-2'}
                        onClick={goBack}
                    >
                        Volver
                    </button>
                </div>
            }
        </div>
    )
}
