import { appName, generalBlue, generalBlueDark } from '../../app-config';
import { H2, WeatherAndForecast } from '../_commons';
import { useGetCurrentUser, useGetIsMobile } from '../../custom-hooks';
import { useNavigate } from 'react-router';

export const HomePage = () => {
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    return (
        <>
            <WeatherAndForecast showWeather={true} showForecast0={false} />

            <H2 title={'BIENVENIDOS A'} mb={'0'} />

            {/* <H2 title={'A'} mt={'0'} mb={'0'} /> */}

            <H2 title={appName.toUpperCase()} mt={'30px'} />

            <div className={'my-5'}>
                <h3 className={'pointer rounded-3'}
                    style={{
                        backgroundColor: generalBlue,
                        color: 'white',
                        fontSize: isMobile ? '2.2rem' : '2.7rem',
                        margin: '40px auto',
                        maxWidth: isMobile ? '95%' : '500px',
                        padding: isMobile ? '16px 0' : '32px 0',
                        textAlign: 'center'
                    }}
                    onClick={() => navigate(user.isAuth ?
                        user.phoneAssignments?.length ? '/telefonica' : 'casa-en-casa'
                        :
                        '/acceso'
                    )}
                    onMouseOver={e => (e.target as HTMLElement).style.backgroundColor = generalBlueDark}
                    onMouseLeave={e => (e.target as HTMLElement).style.backgroundColor = generalBlue}
                >
                    ENTRAR
                </h3>
            </div>
        </>
    )
}
