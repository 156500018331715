import { HTHAssignmentsByTerritory } from './HTHAssignmentsByTerritory';
import { HTHAssignmentsByUser } from './HTHAssignmentsByUser';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { FC, useState } from 'react';
import { generalBlue } from '../../../../app-config';
import { typeHTHTerritory, typeUser } from '@monorepo/models';

type propsType = {
    hthTerritories: typeHTHTerritory[];
    users: typeUser[] | null;
}

const radios = [
    { name: 'Por territorio', value: '1' },
    { name: 'Por usuario', value: '2' }
];

export const HTHAssignments: FC<propsType> = ({ hthTerritories, users }) => {
    const [radioValue, setRadioValue] = useState<'1' | '2'>('1');
    const [territoryToShow, setTerritoryToShow] = useState<typeHTHTerritory | null>(null);

    return (
        <div className={'mt-4'}>

            {/* <h3 className={`mt-4 ${isDarkMode ? 'text-white' : ''}`}>
                Asignaciones
            </h3> */}

            <div className={'row'}>
                <div className={'col-8 offset-md-2'}>
                    <ButtonGroup className={'w-100 mt-3 mb-2'}>
                        {radios.map((radio, idx) =>
                            <ToggleButton key={idx}
                                id={`radio-${idx}`}
                                type={'radio'}
                                className={`${radioValue === radio.value ? '' : 'bg-secondary'} py-2`}
                                style={{ backgroundColor: radioValue === radio.value ? generalBlue : undefined }}
                                checked={radioValue === radio.value}
                                value={radio.value}
                                onChange={e => setRadioValue(e.currentTarget.value as '1' | '2')}
                            >
                                {radio.name}
                            </ToggleButton>
                        )}
                    </ButtonGroup>
                </div>
            </div>

            {radioValue === '1' ?
                hthTerritories.sort((a, b) => parseInt(a.territoryNumber) - parseInt(b.territoryNumber)).map(t =>
                    <HTHAssignmentsByTerritory key={t.territoryNumber}
                        setTerritoryToShow={setTerritoryToShow}
                        t={t}
                        territoryToShow={territoryToShow}
                        users={users}
                    />
                )
                :
                <HTHAssignmentsByUser
                    users={users}
                />
            }
        </div>
    )
}
