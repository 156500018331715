import { Col, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { FC } from 'react';
import { generalRed } from '../../../app-config';
import { useGetIsMobile } from '../../../custom-hooks';

type propsType = {
    isShowingAll: boolean;
    isShowingStatistics: boolean;
    setIsShowingAllStatesHandler: (value: boolean) => void;
    setIsShowingStatisticsHandler: () => void;
}

export const Col0b: FC<propsType> = ({ isShowingAll, isShowingStatistics, setIsShowingAllStatesHandler, setIsShowingStatisticsHandler }) => {
    const isMobile = useGetIsMobile();
    //
    const radios: { name: string; value: '1'|'2'|'3'; }[] = isMobile ?
        [
            { name: isShowingAll ? 'Ver no pred' : 'Viendo no pred', value: '1' },
            { name: isShowingAll ? 'Viendo todos' : 'Ver todos', value: '2' },
            { name: isShowingStatistics ? 'Viendo estad' : 'Ver estad', value: '3' }
        ]
        :
        [
            { name: (isShowingAll || isShowingStatistics) ? 'Ver no predicados' : 'Viendo no predicados', value: '1' },
            { name: isShowingAll ? 'Viendo todos' : 'Ver todos', value: '2' },
            { name: isShowingStatistics ? 'Viendo estadísticas' : 'Ver estadísticas', value: '3' }
        ]
    ;

    // if (isMobile)
    //     radios = user.isTelephonicAdmin ? 
    //         [
    //             { name: isShowingAll ? 'Ver no pred' : 'Viendo no pred', value: '1' },
    //             { name: isShowingAll ? 'Viendo todos' : 'Ver todos', value: '2' },
    //             { name: isShowingStatistics ? 'Viendo estad' : 'Ver estad', value: '3' }
    //         ]
    //         :
    //         [
    //             { name: isShowingAll ? 'Viendo no pred' : 'Ver no pred', value: '1' },
    //             { name: isShowingAll ? 'Viendo todos' : 'Ver todos', value: '2' }
    //         ]
    // else
    //     radios = user.isTelephonicAdmin ?
    //         [
    //             { name: (isShowingAll || isShowingStatistics) ? 'Ver no predicados' : 'Viendo no predicados', value: '1' },
    //             { name: isShowingAll ? 'Viendo todos' : 'Ver todos', value: '2' },
    //             { name: isShowingStatistics ? 'Viendo estadísticas' : 'Ver estadísticas', value: '3' }
    //         ]
    //         :
    //         [
    //             { name: (isShowingAll || isShowingStatistics) ? 'Ver no predicados' : 'Viendo no predicados', value: '1' },
    //             { name: isShowingAll ? 'Viendo todos' : 'Ver todos', value: '2' }
    //         ]
    // ;

    return (
        <Col className={`text-center mb-2 ${isMobile ? '' : 'p-2'}`}>

            <ButtonGroup>
                <ToggleButton type={'radio'}
                    id={'1'}
                    name={"radio"}
                    variant={isShowingAll ? 'dark' : (isShowingStatistics ? 'dark' : 'danger')}
                    style={{
                        backgroundColor: `${isShowingAll || isShowingStatistics ? '' : generalRed}`,
                        padding: '0'
                    }}
                    value={radios[0]?.value}
                >
                    <div
                        onClick={() => setIsShowingAllStatesHandler(false)}
                        style={{ color: 'white', lineHeight: '40px', padding: '0 15px', textDecoration: 'none' }}
                    >
                        {radios[0]?.name}
                    </div>
                </ToggleButton>
                <ToggleButton type={'radio'}
                    id={'2'}
                    className={'p-0'}
                    variant={isShowingAll ? 'danger' : 'dark'}
                    name={"radio"}
                    checked={!!isShowingAll}
                    value={radios[1]?.value}
                >
                    <div
                        onClick={() => setIsShowingAllStatesHandler(true)}
                        style={{ color: 'white', lineHeight: '40px', padding: '0 15px' }}
                    >
                        {radios[1]?.name}
                    </div>
                </ToggleButton>
                {radios && radios[2] &&
                    <ToggleButton type={'radio'}
                        id={'3'}
                        name={"radio"}
                        variant={isShowingStatistics ? 'danger' : 'dark'}
                        style={{ padding: '0' }}
                        checked={!!isShowingStatistics}
                        value={radios[2]?.value}
                    >
                        <div style={{ color: 'white', lineHeight: '40px', padding: '0 15px', textDecoration: 'none' }}
                            onClick={() => setIsShowingStatisticsHandler()}
                        >
                            {radios[2]?.name}
                        </div>
                    </ToggleButton>
                }
            </ButtonGroup>
        </Col>
    )
}
