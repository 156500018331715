import { AssignmentPapers } from './subcomponents/AssignmentPapers';
import { getWeekNumber } from 'src/services';
import { H2, HorizontalSelector, Loading } from 'src/components/_commons';
import { meetingServices } from 'src/services/meetingServices';
import { MidweekMeetingsCreate } from './subcomponents/MidweekMeetingsCreate';
import { typeMidweekMeeting } from '@monorepo/models';
import { useEffect, useMemo, useState } from 'react';

export const AdminsMidweekMeetings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [midweekMeetings, setMidweekMeetings] = useState<typeMidweekMeeting[] | null>(null);
    const [showCreate, setShowCreate] = useState(false);
    const [showPapers, setShowPapers] = useState(false);
    const [weekNumber, setWeekNumber] = useState(getWeekNumber(new Date()));
    const [year, setYear] = useState(new Date().getFullYear());

    const midweekMeeting: typeMidweekMeeting | null = useMemo(() =>
        midweekMeetings?.find(m => m.year === year && m.weekNumber === weekNumber) ?? null
    , [midweekMeetings, weekNumber, year]);

    useEffect(() => {
        meetingServices.Get().then(_meetingDoc => {
            setIsLoading(false);
            if (!_meetingDoc) return;
            setMidweekMeetings(_meetingDoc.midweek);
        });
    }, []);

    return (
        <>
        {showPapers &&
            <AssignmentPapers closeModal={() => setShowPapers(false)} />
        }
        <H2 title={'REUNIONES VIDA Y MINISTERIO'} />
        {isLoading ?
            <Loading />
            :
            <div>
                {!showCreate && <>
                    <div>
                        <button className={'btn btn-general-red'} onClick={() => setShowPapers(true)}>
                            Usar el Generador de Papeles de Asignación
                        </button>
                    </div>
                    <HorizontalSelector
                        label={`Semana ${weekNumber} - ${year}`}
                        setPrevious={() => {
                            if (weekNumber === 1) {
                                setWeekNumber(52);
                                setYear(x => x - 1);
                            } else {
                                setWeekNumber(x => x - 1);
                            }
                        }}
                        setNext={() => {
                            if (weekNumber === 52) {
                                setWeekNumber(1);
                                setYear(x => x + 1);
                            } else {
                                setWeekNumber(x => x + 1);
                            }
                        }}
                    />
                </>}
                {midweekMeeting ?
                    <div className={'mt-5'}>
                        {!showCreate &&
                            <h5>En desarrollo</h5>
                        }
                    </div>
                    :
                    <div>
                        {showCreate ?
                            <MidweekMeetingsCreate
                                cancel={() => setShowCreate(false)}
                                weekNumber={weekNumber}
                                year={year}
                            />
                            :
                            <button className={'btn btn-general-red'} onClick={() => setShowCreate(true)}>
                                Crear Reunión
                            </button>
                        }
                    </div>
                }
            </div>
        }
        </>
    )
}
