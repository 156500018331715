import { typeBlock, typeHousehold } from '@monorepo/models';

export const getBlocks = (households: typeHousehold[]): typeBlock[] => {
    let blocks: typeBlock[] = [];
    for (let i = 1; i < 10; i++) {
        if (households.some(x => x.block === i.toString())) blocks.push(i.toString() as typeBlock);
    }
    return blocks;
}

export const getHouseholdsToShow = (households: typeHousehold[], currentBlock: typeBlock, isShowingAllStates: boolean, isShowingAllAvailable: boolean): typeHousehold[] => {
    let householdsToShow = [ ...households ];
    if (isShowingAllStates && isShowingAllAvailable) {
        householdsToShow = householdsToShow.filter(x =>
            x.block === currentBlock
        );
    } else if (!isShowingAllStates && isShowingAllAvailable) {
        householdsToShow = householdsToShow.filter(x =>
            x.block === currentBlock && ((x.callingState === 'No predicado' && x.notSubscribed !== true) || x.doNotMove)
        );
    } else if (isShowingAllStates && !isShowingAllAvailable) {
        householdsToShow = householdsToShow.filter(x =>
            x.block === currentBlock
        );
    } else {
        householdsToShow = householdsToShow.filter(x =>
            x.block === currentBlock && ((x.callingState === 'No predicado' && x.notSubscribed !== true) || x.doNotMove)
        );
    }
    return householdsToShow;
}

export const getHouseholdVariant = (households: typeHousehold[]): typeHousehold[] => {
    if (!households || !households.length) return households;
    return households.map(x => {
        if (x.callingState === 'No predicado') x = { ...x, variant: 'success' };
        else if (x.callingState === 'Contestó') x = { ...x, variant: 'primary' };
        else if (x.callingState === 'No contestó') x = { ...x, variant: 'warning' };
        else if (x.callingState === 'A dejar carta') x = { ...x, variant: 'danger' };
        else if (x.callingState === 'No llamar') x = { ...x, variant: 'dark' };
        return x;
    });
}

export const getNumberOfFreePhones = (households: typeHousehold[]): number => (
    households.filter(x => !x.notSubscribed && x.callingState === 'No predicado').length
);

export const getReducedPhoneNumber = (phoneNumber: string): string => {
    if (!phoneNumber || phoneNumber.length < 7) return phoneNumber;
    if (phoneNumber.substring(0, 6) === "54-11-") return phoneNumber.substring(6);
    if (phoneNumber.substring(0, 3) === "54-") return phoneNumber.substring(3);
    return phoneNumber;
}
