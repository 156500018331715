import { deleteHTHPolygonFaceService, maskTheBlock } from '../../../services';
import { FC, useMemo } from 'react';
import { setValuesAndOpenAlertModalReducer } from '../../../store';
import { typeHTHTerritory, typePolygon } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetConfig } from '../../../custom-hooks';

type propsType = {
    currentFace: typePolygon;
    territoryHTH: typeHTHTerritory;
}

export const HTHDeleteFaceButton: FC<propsType> = ({ currentFace, territoryHTH }) => {
    const config = useGetConfig();
    const dispatch = useDispatch();

    const openConfirmModalHTHDeleteFaceHandler = (): void => {
        if (!currentFace || !territoryHTH || !territoryHTH.map || !territoryHTH.map.polygons) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: "¿Eliminar cara?",
            message: `Se va a eliminar esta CARA ${currentFace.street} de MANZANA ${maskTheBlock(currentFace.block, config.usingLettersForBlocks)}. Esta acción no tiene vuelta atrás.`,
            execution: deleteHTHFaceHandler
        }));
    }

    const deleteHTHFaceHandler = async (): Promise<void> => {
        if (!currentFace || !territoryHTH || !territoryHTH.map || !territoryHTH.map.polygons) return;
        deleteHTHPolygonFaceService(territoryHTH.territoryNumber, currentFace.block, currentFace.face, currentFace.id).then((success: boolean) => {
            if (!success) return dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: "Algo falló",
                message: `No se pudo eliminar la cara ${currentFace.street} de la manzana ${maskTheBlock(currentFace.block, config.usingLettersForBlocks)} (territorio ${territoryHTH.territoryNumber})`,
                animation: 2
            }));
        });
    }

    const isDisabled: boolean = useMemo(() => {
        return !!currentFace.buildings?.length || !!currentFace.doNotCalls?.length || !!currentFace.observations?.length;
    }, [currentFace.buildings?.length, currentFace.doNotCalls?.length, currentFace.observations?.length]);
    
    return (
        <button
            className={'btn btn-general-red btn-size12 d-block mx-auto py-3'}
            style={{ marginTop: '100px', width: '90%' }}
            onClick={() => openConfirmModalHTHDeleteFaceHandler()}
            disabled={isDisabled}
        >
            ELIMINAR CARA
        </button>
    )
}
