import { AdminsAVUshers } from './av-ushers/AdminsAVUshers';
import { AdminsBoard } from './board/AdminsBoard';
import { AdminsCarts } from './carts/AdminsCarts';
import { AdminsConfig } from './config/AdminsConfig';
import { AdminsGroups } from './groups/AdminsGroups';
import { AdminsHTH } from './hth/AdminsHTH';
import { AdminsLogs } from './logs/AdminsLogs';
import { AdminsMeetingAttendance } from './meeting-attendance/AdminsMeetingAttendance';
import { AdminsMidweekMeetings } from './midweek-meetings/AdminsMidweekMeetings';
import { AdminsMonthlyReports } from './monthly-reports/AdminsMonthlyReports';
import { AdminsNotificacions } from './notifications/AdminsNotificacions';
import { AdminsPublicMeetings } from './public-meetings/AdminsPublicMeetings';
import { AdminsPublicMinistry } from './public-ministry/AdminsPublicMinistry';
import { AdminsTelephonic } from './telephonic/AdminsTelephonic';
import { AdminsUsers } from './users/AdminsUsers';
import { H2 } from '../_commons';
import { hideLoadingModalReducer, showLoadingModalReducer } from '../../store';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from '../../custom-hooks';
import { useNavigate } from 'react-router';
import { useState, useEffect, useMemo } from 'react';

const classes = 'btn btn-general-blue btn-size12 d-block mx-auto mt-2 mb-0';
const styles = { width: '200px' };
type typeAdminsSections = '' | 'attendance' | 'av-ushers' | 'board' | 'carts' | 'config' | 'groups' | 'hth' | 'logs' | 'midweek-meetings' | 'notifications' | 'public-meetings' | 'public-ministry' | 'reports' | 'telephonic' | 'users';

export const AdminsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const currentSection: typeAdminsSections = useMemo(() => {
        const relativePath = window.location.pathname as string;
        if (relativePath.startsWith('/admins/asistencia')) return 'attendance';
        if (relativePath.startsWith('/admins/av-y-acomodadores')) return 'av-ushers';
        if (relativePath.startsWith('/admins/carritos')) return 'carts';
        if (relativePath.startsWith('/admins/casa-en-casa')) return 'hth';
        if (relativePath.startsWith('/admins/config')) return 'config';
        if (relativePath.startsWith('/admins/grupos')) return 'groups';
        if (relativePath.startsWith('/admins/informes')) return 'reports';
        if (relativePath.startsWith('/admins/logs')) return 'logs';
        if (relativePath.startsWith('/admins/notificaciones')) return 'notifications';
        if (relativePath.startsWith('/admins/reuniones-entresemana')) return 'midweek-meetings';
        if (relativePath.startsWith('/admins/reuniones-findesemana')) return 'public-meetings';
        if (relativePath.startsWith('/admins/salidas')) return 'public-ministry';
        if (relativePath.startsWith('/admins/tablero')) return 'board';
        if (relativePath.startsWith('/admins/telefonica')) return 'telephonic';
        if (relativePath.startsWith('/admins/usuarios')) return 'users';
        return '';
    // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        if (isLoading) dispatch(showLoadingModalReducer());
        else dispatch(hideLoadingModalReducer());
    }, [dispatch, isLoading]);

    return (
        <div>
            <div className={currentSection === '' ? '' : 'd-none'}>
                <H2 title={'ADMINISTRADORES'} />
                <div className={`card ${isDarkMode ? 'bg-dark text-white border-dark' : ''} mb-3`}>
                    <div className={`card-header ${isDarkMode ? 'text-white border-light' : ''}`}>
                        <h3 className={`${isMobile ? 'text-center py-1' : ''} m-0`}>
                            Predicación
                        </h3>
                    </div>
                    <div className={'card-body row'}>
                        {user.isHthAdmin &&
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/casa-en-casa')}
                                >
                                    Casa en Casa
                                </button>
                            </div>
                        }
                        {user.isTelephonicAdmin &&
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/telefonica')}
                                >
                                    Telefónica
                                </button>
                            </div>
                        }
                        {user.isCartsAdmin &&
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/carritos')}
                                >
                                    Carritos
                                </button>
                            </div>
                        }
                        {user.isBoardAdmin &&
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/salidas')}
                                >
                                    Servicio del Campo
                                </button>
                            </div>
                        }
                    </div>
                </div>
                {user.isBoardAdmin && <>
                    <div className={`card ${isDarkMode ? 'bg-dark text-white border-dark' : ''} mb-3`}>
                        <div className={`card-header ${isDarkMode ? 'text-white border-light' : ''}`}>
                            <h3 className={`${isMobile ? 'text-center py-1' : ''} m-0`}>
                                Reuniones
                            </h3>
                        </div>
                        <div className={'card-body row'}>
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/reuniones-entresemana')}
                                >
                                    Vida y Ministerio
                                </button>
                            </div>
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/reuniones-findesemana')}
                                >
                                    Reunión Pública
                                </button>
                            </div>
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/av-y-acomodadores')}
                                >
                                    AV y Acomodadores
                                </button>
                            </div>
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/asistencia')}
                                >
                                    Asistencia
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={`card ${isDarkMode ? 'bg-dark text-white border-dark' : ''} mb-3`}>
                        <div className={`card-header ${isDarkMode ? 'text-white border-light' : ''}`}>
                            <h3 className={`${isMobile ? 'text-center py-1' : ''} m-0`}>
                                Usuarios
                            </h3>
                        </div>
                        <div className={'card-body row'}>
                            {user.isAdmin && <>
                                <div className={isMobile ? 'col-12' : 'col-auto'}>
                                    <button className={classes}
                                        style={styles}
                                        onClick={() => navigate('/admins/usuarios')}
                                    >
                                        Usuarios
                                    </button>
                                </div>
                                <div className={isMobile ? 'col-12' : 'col-auto'}>
                                    <button className={classes}
                                        style={styles}
                                        onClick={() => navigate('/admins/grupos')}
                                    >
                                        Grupos
                                    </button>
                                </div>
                                <div className={isMobile ? 'col-12' : 'col-auto'}>
                                    <button className={classes}
                                        style={styles}
                                        onClick={() => navigate('/admins/notificaciones')}
                                    >
                                        Notificaciones
                                    </button>
                                </div>
                            </>}
                            {user.isBoardAdmin &&
                                <div className={isMobile ? 'col-12' : 'col-auto'}>
                                    <button className={classes}
                                        style={styles}
                                        onClick={() => navigate('/admins/informes')}
                                    >
                                        Informes
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </>}
                <div className={`card ${isDarkMode ? 'bg-dark text-white border-dark' : ''} mb-3`}>
                    <div className={`card-header ${isDarkMode ? 'text-white border-light' : ''}`}>
                        <h3 className={`${isMobile ? 'text-center py-1' : ''} m-0`}>
                            Sistema
                        </h3>
                    </div>
                    <div className={'card-body row'}>
                        {user.isBoardAdmin &&
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/tablero')}
                                >
                                    Tablero
                                </button>
                            </div>
                        }
                        <div className={isMobile ? 'col-12' : 'col-auto'}>
                            <button className={classes}
                                style={styles}
                                onClick={() => navigate('/admins/logs')}
                            >
                                Logs
                            </button>
                        </div>
                        {user.isAdmin &&
                            <div className={isMobile ? 'col-12' : 'col-auto'}>
                                <button className={classes}
                                    style={styles}
                                    onClick={() => navigate('/admins/config')}
                                >
                                    Configuración
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {currentSection === 'attendance' && <AdminsMeetingAttendance />}
            {currentSection === 'av-ushers' && <AdminsAVUshers />}
            {currentSection === 'board' && <AdminsBoard />}
            {currentSection === 'carts' && <AdminsCarts />}
            {currentSection === 'config' && <AdminsConfig />}
            {currentSection === 'groups' && <AdminsGroups />}
            {currentSection === 'hth' && <AdminsHTH />}
            {currentSection === 'logs' && <AdminsLogs />}
            {currentSection === 'midweek-meetings' && <AdminsMidweekMeetings />}
            {currentSection === 'notifications' && <AdminsNotificacions />}
            {currentSection === 'public-meetings' && <AdminsPublicMeetings />}
            {currentSection === 'public-ministry' && <AdminsPublicMinistry />}
            {currentSection === 'reports' && <AdminsMonthlyReports />}
            {currentSection === 'telephonic' && <AdminsTelephonic />}
            {currentSection === 'users' && <AdminsUsers setIsLoading={setIsLoading} />}
        </div>
    )
}
