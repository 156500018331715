import { Card, ListGroup } from 'react-bootstrap';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { hthConfigOptions } from '../../../../app-config';
import { getAllLogsService, maskTheBlock, maskTheFace } from '../../../../services';
import { typeLogsDoc } from '@monorepo/models';
import { useGetConfig, useGetIsDarkMode } from '../../../../custom-hooks';

type propsType = {
    activeLog: typeLogsDoc;
    congregationForLogs: number;
    setActiveLog: Dispatch<SetStateAction<typeLogsDoc | null>>;
    setLogs: Dispatch<SetStateAction<typeLogsDoc[] | null>>;
}

export const LogsCard: FC<propsType> = ({ activeLog, congregationForLogs, setActiveLog, setLogs }) => {
    const [isLoading, setIsLoading] = useState(false);
    const config = useGetConfig();
    const isDarkMode = useGetIsDarkMode();

    const maskBlockAndFaceHandler = (log: string) => {
        hthConfigOptions.blocks.forEach(b => {
            log = log.replace(`manzana ${b}`, `manzana ${maskTheBlock(b, config.usingLettersForBlocks)}`);
            log = log.replace(`Manzana ${b}`, `Manzana ${maskTheBlock(b, config.usingLettersForBlocks)}`);
        })
        hthConfigOptions.faces.forEach(f => {
            log = log.replace(`cara ${f}`, `cara ${maskTheFace(f, config.usingLettersForBlocks)}`);
            log = log.replace(`Cara ${f}`, `Cara ${maskTheFace(f, config.usingLettersForBlocks)}`);
        })
        return log;
    }

    const getAll = async () => {
        setIsLoading(true);
        const data = await getAllLogsService(true, activeLog.type, congregationForLogs);
        setIsLoading(false);
        const logs = data?.log?.logs;
        if (!logs) return;
        setLogs(x => !x ? x : x.map(l => l.congregation === congregationForLogs && l.type === activeLog.type ?
            ({ ...l, hasAll: true, logs }) : l)
        );
        setActiveLog(x => x ? ({ ...x, hasAll: true, logs }) : x);
    }

    return (
        <Card className={`${isDarkMode ? 'bg-dark text-white' : ''} mt-4 mb-4`}>
            <Card.Header className={'text-center h1 py-4'}>
                <div className={'mb-3'}>
                    {activeLog.title} <span className={'small fs-5'}>(viendo {activeLog.logs.length})</span>
                </div>
                {!activeLog.hasAll && activeLog.logs.length === 200 &&
                    <button className={'btn btn-general-blue d-block mt-3 mx-auto'} onClick={getAll}>
                        {isLoading ? '...trayendo...' : 'Traer también los más viejos'}
                    </button>
                }
            </Card.Header>
            <ListGroup variant={'flush'}>
                {activeLog.logs.map(log =>
                    <ListGroup.Item key={log.timestamp} className={isDarkMode ? 'bg-dark text-white' : ''}>
                        {maskBlockAndFaceHandler(log.logText)}
                    </ListGroup.Item>
                )}
            </ListGroup>
        </Card>
    )
}
