import { Container, Modal } from 'react-bootstrap';
import { FC } from 'react';
import { typeUser } from '@monorepo/models';

type propsType = {
    closeModal: () => void;
    users: typeUser[];
}

export const UsersRolesModal: FC<propsType> = ({ closeModal, users }) => {
    return (
        <Modal
            fullscreen={'md-down'}
            onHide={closeModal}
            show={true}
            size={undefined}
        >
            <Modal.Header closeButton>
                <Modal.Title>Administradores de la Aplicación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <div className={'bg-secondary text-white h5 p-2 mt-2'}>Administradores Generales:</div>
                    {users.filter(u => u.roles.includes(1)).length ?
                        users.filter(u => u.roles.includes(1)).map(u =>
                            <div key={u.id} className={'bg-light text-end'}>{u.email}</div>
                        )
                        :
                        <div className={'bg-light text-end'}>(Ninguno)</div>
                    }
                    <div className={'bg-secondary text-white h5 p-2 mt-2'}>Administradores de Casa en Casa:</div>
                    {users.filter(u => u.roles.includes(2)).length ?
                        users.filter(u => u.roles.includes(2)).map(u =>
                            <div key={u.id} className={'bg-light text-end'}>{u.email}</div>
                        )
                        :
                        <div className={'bg-light text-end'}>(Ninguno)</div>
                    }
                    <div className={'bg-secondary text-white h5 p-2 mt-2'}>Administradores de Edificios:</div>
                    {users.filter(u => u.roles.includes(5)).length ?
                        users.filter(u => u.roles.includes(5)).map(u =>
                            <div key={u.id} className={'bg-light text-end'}>{u.email}</div>
                        )
                        :
                        <div className={'bg-light text-end'}>(Ninguno)</div>
                    }
                    <div className={'bg-secondary text-white h5 p-2 mt-2'}>Administradores de Telefónica:</div>
                    {users.filter(u => u.roles.includes(3)).length ?
                        users.filter(u => u.roles.includes(3)).map(u =>
                            <div key={u.id} className={'bg-light text-end'}>{u.email}</div>
                        )
                        :
                        <div className={'bg-light text-end'}>(Ninguno)</div>
                    }
                    <div className={'bg-secondary text-white h5 p-2 mt-2'}>Administradores de Carritos:</div>
                    {users.filter(u => u.roles.includes(4)).length ?
                        users.filter(u => u.roles.includes(4)).map(u =>
                            <div key={u.id} className={'bg-light text-end'}>{u.email}</div>
                        )
                        :
                        <div className={'bg-light text-end'}>(Ninguno)</div>
                    }
                    <div className={'bg-secondary text-white h5 p-2 mt-2'}>Administradores de Tablero:</div>
                    {users.filter(u => u.roles.includes(6)).length ?
                        users.filter(u => u.roles.includes(6)).map(u =>
                            <div key={u.id} className={'bg-light text-end'}>{u.email}</div>
                        )
                        :
                        <div className={'bg-light text-end'}>(Ninguno)</div>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
