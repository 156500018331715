import { BoardSectionsItems } from './BoardSectionsItems';
import { BoardSectionsItemsAddModal } from './BoardSectionsItemsAddModal';
import { BoardSectionsItemsArchivedFilesModal } from './BoardSectionsItemsArchivedFilesModal';
import { BoardSectionsTitleModal } from './BoardSectionsTitleModal';
import { deleteBoardSectionService, reorderBoardSectionsService } from '../../../../services';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { FaExternalLinkAlt, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { generalBlue } from '../../../../app-config';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../../store';
import { typeBoardSection } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile } from '../../../../custom-hooks';

type propsType = {
    section: typeBoardSection;
    sections: typeBoardSection[];
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardSection: FC<propsType> = ({ section, sections, setCounterReload }) => {
    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [showArchivedFilesModal, setShowArchivedFilesModal] = useState(false);
    const [showChangeTitleModal, setShowChangeTitleModal] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const reorder = async (section: typeBoardSection, up: boolean) => {
        if (!sections) return;
        dispatch(showLoadingModalReducer());
        const reorderedSections = sections.map(s => ({
            ...s,
            order:
                up ?
                s.id === section.id ? s.order - 1 : s.order === section.order - 1 ? s.order + 1 : s.order
                :
                s.id === section.id ? s.order + 1 : s.order === section.order + 1 ? s.order - 1 : s.order
        }));
        const success = await reorderBoardSectionsService(reorderedSections);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo guardar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    const deleteSection = (section: typeBoardSection) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar Eliminar',
            message: `Se van a eliminar la sección '${section.title}' y todos sus archivos. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteBoardSectionService(section);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setCounterReload(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Hubo un error',
                        message: 'Algo falló y no se pudo eliminar',
                        mode: 'alert',
                        animation: 2
                    }));
                }
            }
        }));
    }

    return (
        <div className={`card ${isDarkMode ? 'bg-dark text-white' : ''} my-5 mx-auto`} style={{ maxWidth: '900px' }}>
            {showAddItemModal &&
                <BoardSectionsItemsAddModal
                    closeModal={() => setShowAddItemModal(false)}
                    section={section}
                    setCounterReload={setCounterReload}
                />
            }
            {showChangeTitleModal &&
                <BoardSectionsTitleModal
                    closeModal={() => setShowChangeTitleModal(false)}
                    section={section}
                    sections={sections}
                    setCounterReload={setCounterReload}
                />
            }
            {showArchivedFilesModal &&
                <BoardSectionsItemsArchivedFilesModal
                    closeModal={() => setShowArchivedFilesModal(false)}
                    section={section}
                    setCounterReload={setCounterReload}
                />
            }
            <div className={`card-header d-flex justify-content-between align-items-center ${isDarkMode ? 'border-light' : ''} pt-3 ps-3`}>
                <h3>
                    SECCIÓN {section.order}: {section.title.toUpperCase()}
                </h3>
                <div className={'text-nowrap'}>
                    {sections.length > 1 && section.order !== 1 &&
                        <button className={'btn btn-link p-0 mb-2'}
                            onClick={() => reorder(section, true)}
                        >
                            <FaArrowUp size={isMobile ? 15 : 25} color={generalBlue} />
                        </button>
                    }
                    {sections.length > 1 && section.order !== Math.max(...sections.map(s => s.order)) &&
                        <button className={'btn btn-link p-0 mb-2 ms-2'}
                            onClick={() => reorder(section, false)}
                        >
                            <FaArrowDown size={isMobile ? 15 : 25} color={generalBlue} />
                        </button>
                    }
                </div>
            </div>
            <div className={'card-body'}>
                <BoardSectionsItems
                    section={section}
                    setCounterReload={setCounterReload}
                />
                <div className={'container text-center mt-4 mb-2'}>
                    <button className={'btn btn-general-blue text-nowrap me-3'}
                        onClick={() => setShowChangeTitleModal(true)}
                    >
                        {isMobile ?
                            <>Título <FaExternalLinkAlt className={'ms-1 mb-1'} /></>
                            :
                            'Cambiar título'
                        }
                    </button>
                    <button className={'btn btn-general-blue text-nowrap me-3'}
                        onClick={() => setShowAddItemModal(true)}
                    >
                        {isMobile ?
                            <>Ítem <FaExternalLinkAlt className={'ms-1 mb-1'} /></>
                            :
                            <>Agregar Ítem</>
                        }
                    </button>
                    {!!section.items.filter(i => i.type === 'file' && i.archived).length &&
                        <button className={'btn btn-general-blue text-nowrap me-3'}
                            onClick={() => setShowArchivedFilesModal(true)}
                        >
                            {isMobile ?
                            <>Archivados <FaExternalLinkAlt className={'ms-1 mb-1'} /></>
                            :
                            'Ver PDFs archivados'
                        }
                        </button>
                    }
                    <button className={'btn btn-general-red text-nowrap'}
                        onClick={() => deleteSection(section)}
                    >
                        {isMobile ? 'Eliminar' : 'Eliminar Sección'}
                    </button>
                </div>
            </div>
        </div>
    )
}
