import { daysOfTheWeekP, months } from '../../app-config';
import { deleteMyAccountService, logoutAllService } from '../../services';
import { getTimeFromPeriodObject } from '@monorepo/helpers';
import { H2, Hr } from '../_commons';
import { setValuesAndOpenAlertModalReducer } from '../../store';
import { typeCartPeriod } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, useGetIsDarkMode } from '../../custom-hooks';
import { useNavigate } from 'react-router';
import { UserChangeEmail } from './subcomponents/UserChangeEmail';
import { UserChangePassword } from './subcomponents/UserChangePassword';
import { UserPersonalData } from './subcomponents/UserPersonalData';
import { useState } from 'react';

export const MyUserPage = () => {
    const [showChangeEmail, setShowChangeEmail] = useState(false);
    const [showChangePsw, setShowChangePsw] = useState(false);
    const [showPersonalData, setShowPersonalData] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const openAlertModalHandler = (title: string, message: string, animation?: number): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'alert',
            title,
            message,
            animation
        }));
    }

    const openConfirmModalHandler = (title: string, message: string, execution: Function): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title,
            message,
            execution
        }));
    }

    const logoutAll = async (): Promise<void> => {
        const success: boolean = await logoutAllService();
        if (!success)
            return openAlertModalHandler("Algo falló", "Intentar de nuevo", 2);
        openAlertModalHandler("Cierre exitoso", "", 1);
    }

    const deleteAccount = async (): Promise<void> => {
        const success: boolean = await deleteMyAccountService();
        if (!success)
            return openAlertModalHandler("Algo falló", "Intentar de nuevo", 2);
        window.location.reload();
    }

    return (
        <>
            <H2 title={'Mi Usuario'} />

            {showPersonalData &&
                <UserPersonalData
                    closeModal={() => setShowPersonalData(false)}
                />
            }

            <div className={`card text-center ${isDarkMode ? 'bg-dark text-white' : ''}`}
                style={{ margin: '30px auto', maxWidth: '700px', padding: '25px' }}
            >
                <h1 className={'mt-2 py-2'}>
                    {user.email}
                </h1>

                <Hr />

                <div className={'my-3'}>
                    <h3>Roles en la aplicación:</h3>
                    <h4>{user.rolesAsText}</h4>
                </div>

                <Hr />

                <div className={'my-3'}>
                    <h3>Asignaciones de las Reuniones:</h3>
                    <h4>Ninguna (en desarrollo)</h4>
                </div>

                <Hr />
                
                <div className={'my-3'}>

                    <h3>Territorios de Casa en Casa asignados:</h3>

                    {!!user.hthAssignments?.length ?
                        ([...user.hthAssignments]).sort((a1, a2) => a1 - a2).map(territoryNumber =>
                            <button key={territoryNumber}
                                className={'btn btn-general-blue d-inline-block text-center mt-3 mx-1 px-0'}
                                style={{ fontWeight: 'bolder', width: '65px' }}
                                onClick={() => navigate(`/casa-en-casa/${territoryNumber}`)}
                            >
                                {territoryNumber}
                            </button>
                        )
                        :
                        <h4>Ninguno</h4>
                    }
                </div>

                <Hr />
                
                <div className={'my-3'}>
                    <h3>Territorios de Telefónica asignados:</h3>
                    {!!user.phoneAssignments?.length ?
                        [...user.phoneAssignments].sort((a1, a2) => a1 - a2).map(territoryNumber =>
                            <button key={territoryNumber}
                                className={'btn btn-general-red d-inline-block text-center mt-3 mx-1 px-0'}
                                onClick={() => navigate(`/telefonica/${territoryNumber}`)}
                                style={{ fontWeight: 'bolder', width: '65px' }}
                            >
                                {territoryNumber}
                            </button>
                        )
                        :
                        <h4>Ninguno</h4>
                    }
                </div>

                <Hr />

                <div className={'my-3'}>
                    <h3>Asignaciones de Carritos/Exhibidores:</h3>
                    {!!user.cartAssignments?.length ?
                        (user.cartAssignments
                            ?.filter(a => new Date(a.year, a.month) >= new Date(new Date().getFullYear(), new Date().getMonth()))
                            .map(a =>
                                <button key={a.id}
                                    className={'btn btn-success text-start w-100 mt-3'}
                                    style={{ fontWeight: 'bolder' }}
                                    onClick={() => navigate(`/tablero`)}
                                >
                                    {daysOfTheWeekP[a.weekday]} de {months[a.month]} {a.year} {getTimeFromPeriodObject(a as typeCartPeriod)} con {a.participants?.find(x => x.userId !== user.id)?.name} {a.participants?.find(x => x.userId !== user.id)?.lastName} - {a.name}
                                </button>
                            )
                        )
                        :
                        <h4>Ninguno</h4>
                    }
                </div>

                <Hr />

                {!showChangePsw && !showChangeEmail && <>
                    <button className={'btn btn-general-blue py-2'}
                        style={{ width: '350px', margin: '40px auto 0' }}
                        onClick={() => setShowPersonalData(true)}
                    >
                        Ver mis datos en la aplicación
                    </button>
                    <button className={'btn btn-general-blue py-2'}
                        style={{ width: '350px', margin: '15px auto 0' }}
                        onClick={() => setShowChangePsw(true)}
                    >
                        Cambiar contraseña
                    </button>
                    <button className={'btn btn-general-blue py-2'}
                        style={{ width: '350px', margin: '15px auto 0' }}
                        onClick={() => setShowChangeEmail(true)}
                    >
                        Cambiar de dirección de email
                    </button>
                    <button className={'btn btn-general-blue py-2'}
                        style={{ width: '350px', margin: '15px auto 0' }}
                        onClick={() => openConfirmModalHandler("¿Cerrar sesiones?", "Esta opción cerrará todas las sesiones en todos los dispositivos en que se haya ingresado excepto en este", logoutAll)}
                    >
                        Cerrar sesión en todos los dispositivos
                    </button>

                    <button className={'btn btn-general-red py-2'}
                        style={{ width: '350px', margin: '15px auto' }}
                        onClick={() => openConfirmModalHandler("ELIMINAR LA CUENTA", "Esta opción ELIMINARÁ IRREVERSIBLEMENTE ESTA CUENTA. SE PERDERÁN TODOS LOS DATOS ASOCIADOS A ELLA Y NO PODRÁN SER RECUPERADOS.", deleteAccount)}
                    >
                        Eliminar mi cuenta
                    </button>
                </>}

            </div>

            {showChangePsw &&
                <UserChangePassword
                    openAlertModalHandler={openAlertModalHandler}
                    openConfirmModalHandler={openConfirmModalHandler}
                    setShowChangePsw={setShowChangePsw}
                />
            }

            {showChangeEmail &&
                <UserChangeEmail
                    openAlertModalHandler={openAlertModalHandler}
                    openConfirmModalHandler={openConfirmModalHandler}
                    setShowChangeEmail={setShowChangeEmail}
                />
            }
        </>
    )
}
