"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationTypes = void 0;
///////////////////////////////////////////////////////////////////////////////////////////////////
exports.notificationTypes = [
    { label: 'Capitanes Casa en Casa', type: 'hth' },
    { label: 'Asignaciones de Carritos', type: 'carts' },
    { label: 'Audio, Video y Acomodadores', type: 'av-ushers' },
    { label: 'Vida y Ministerio', type: 'midweek' },
    { label: 'Reunión Pública', type: 'public' },
    { label: 'Limpieza por Grupos', type: 'cleaning' }
];
