import { FC } from 'react';

type propsType = {
    closeModal: () => void;
    date: string;
    handleSubmit: () => void;
    isLoading: boolean;
    notes: string;
    inPerson: number;
    youtube: number;
    zoom: number;
}

export const MeetingAttendanceFormConfirm: FC<propsType> = ({
    closeModal, date, handleSubmit, isLoading, notes, inPerson: presencial, youtube, zoom
}) => {
    return (
        <div className={'modal fade show d-block'}
            tabIndex={-1}
            role={'dialog'}
        >
            <div className={'modal-dialog'} role={'document'}>
                <div className={'modal-content'}>
                    <div className={'modal-header'}>
                        <h5 className={'modal-title'}>
                            Confirmar Agregar Asistencia
                        </h5>
                        <button type={'button'}
                            className={'btn-close'}
                            onClick={closeModal}
                        ></button>
                    </div>
                    <div className={'modal-body'}>
                        <p>¿Quieres confirmar la asistencia con los siguientes detalles?</p>
                        <ul>
                            <li>
                                <strong>Fecha:</strong> {date}
                            </li>
                            <li>
                                <strong>Presencial:</strong> {presencial}
                            </li>
                            <li>
                                <strong>Zoom:</strong> {zoom}
                            </li>
                            <li>
                                <strong>YouTube:</strong> {youtube}
                            </li>
                            <li>
                                <strong>Notas:</strong> {notes}
                            </li>
                        </ul>
                    </div>
                    <div className={'modal-footer'}>
                        <button type={'button'}
                            className={'btn btn-secondary'}
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                        <button type={'button'}
                            className={'btn btn-success'}
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ?
                                <>
                                <span className={'spinner-border spinner-border-sm me-2'}
                                    role={'status'}
                                    aria-hidden={'true'}
                                ></span>
                                Agregando...
                                </>
                                :
                                'Confirmar'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}