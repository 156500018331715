"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.boardItemTypes = void 0;
///////////////////////////////////////////////////////////////////////////////////////////////////
exports.boardItemTypes = [
    { type: 'audioVisual', label: 'Asignaciones Audio y Video' },
    { type: 'carts', label: 'Asignaciones Carritos' },
    { type: 'file', label: 'Archivo PDF' },
    { type: 'hthAssignments', label: 'Salidas de Predicación' },
    { type: 'midweekMeeting', label: 'Asignaciones Reuniones de Entresemana' },
    { type: 'text', label: 'Texto' },
    { type: 'ushers', label: 'Asignaciones Acomodadores para las Reuniones' },
    { type: 'weekendMeeting', label: 'Asignaciones Reuniones de Entresemana' }
];
