import { Container, FloatingLabel, Form } from 'react-bootstrap';
import { emailPattern } from '../../app-config';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../store';
import { registerUserService } from '../../services';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile, useGetRecaptchaToken } from '../../custom-hooks';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

export const NewUserPage = () => {
    const [params] = useSearchParams();
    //
    const [confPassword, setConfPassword] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()
    const email = params.get('email') || ''
    const getRecaptchaToken = useGetRecaptchaToken();
    const id = params.get('id') || ''  // invitationId
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();
    const team = params.get('team') || "0";

    const openAlertModalHandler = (title: string, message: string, animation?: number, execution?: Function): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'alert',
            title,
            message,
            animation,
            execution
        }));
    }

    const createAccountByEmailInvitationHandler = async () => {
        if (!id || !emailPattern.test(email) || password.length < 8 || confPassword.length < 8 || password !== confPassword)
            return openAlertModalHandler("Faltan datos", '');
        const congr = parseInt(team);
        if (isNaN(congr) || !Number.isInteger(congr))
            return openAlertModalHandler("Hay un error en los datos", '');
        const recaptchaToken = await getRecaptchaToken();
        if (!recaptchaToken)
            return openAlertModalHandler("Problemas (1)", "Refrescar la página", 2);
        dispatch(showLoadingModalReducer());
        const response = await registerUserService(congr, email, id, password, recaptchaToken);
        dispatch(hideLoadingModalReducer());
        if (response?.success) {
            openAlertModalHandler("Usuario creado con éxito", '', 1, () => navigate('/'));
            return;
        }
        if (response?.recaptchaFails) {
            openAlertModalHandler("Algo falló en la página; se va a refrescar", '', 2, () => window.location.reload());
        } else if (response?.userExists) {
            openAlertModalHandler("Ya hay una cuenta con esta dirección de email", '', 2, () => navigate('/acceso'));
        } else if (response?.expired) {
            openAlertModalHandler("Esta invitación ya expiró; pedir otra", '', 2, () => navigate('/'));
        } else {
            openAlertModalHandler("Algo salió mal", '', 2);
        }
    }

    return (
        <Container className={'maxw-400'}>

            <h2 className={`text-center mt-5 mx-auto ${isDarkMode ? 'text-white' : ''}`}
                style={{
                    fontSize: isMobile ? '1.7rem' : '2rem',
                    maxWidth: '90%',
                    textShadow: '0 0 1px gray'
                }}
            >
                COMPLETAR PARA CREAR UN USUARIO
            </h2>

            <Container style={{ maxWidth: '500px', padding: isMobile ? '35px 30px 0' : '35px 0 0' }}>

                <FloatingLabel
                    className={'mb-3 text-dark'}
                    label={"Correo electrónico"}
                >
                    <Form.Control
                        type={'email'}
                        className={'form-control'}
                        placeholder={''}
                        value={email}
                        onChange={() => {}}
                        disabled={true}
                    />
                </FloatingLabel>

                <FloatingLabel
                    className={'mb-3 text-dark'}
                    label={"Nueva Contraseña"}
                >
                    <Form.Control
                        type={'password'}
                        className={'form-control'}
                        placeholder={''}
                        value={password}
                        onChange={e => setPassword((e.target as HTMLInputElement).value)}
                        autoFocus
                    />
                </FloatingLabel>

                <FloatingLabel
                    className={'mb-3 text-dark'}
                    label={"Confirmar Nueva Contraseña"}
                >
                    <Form.Control
                        type={'password'}
                        className={'form-control'}
                        placeholder={''}
                        value={confPassword}
                        onChange={e => setConfPassword((e.target as HTMLInputElement).value)}
                        onKeyDown={e => e.key === 'Enter' && !(!emailPattern.test(email) || password.length < 8 || confPassword.length < 8 || password !== confPassword) ? createAccountByEmailInvitationHandler() : null }
                    />
                </FloatingLabel>

                {/* <FloatingLabel
                    label={"Número de Grupo"}
                    className={'mb-3 text-dark'}
                >
                    <Form.Control
                        type={'number'}
                        className={'form-control'}
                        placeholder={''}
                        value={group ? group : ''}
                        min={'1'}
                        onChange={e => setGroup(parseInt(e.target.value))}
                        onKeyDown={e => e.key === 'Enter' && !(!emailPattern.test(email) || password.length < 8 || confPassword.length < 8 || password !== confPassword || !group) ? createAccountByEmailInvitationHandler() : null }
                    />
                </FloatingLabel> */}

                <button
                    className={'btn btn-general-blue d-block w-100 mt-5'}
                    style={{ fontWeight: 'bolder', height: '50px' }}
                    onClick={() => createAccountByEmailInvitationHandler()}
                    disabled={!emailPattern.test(email) || password.length < 8 || confPassword.length < 8 || password !== confPassword}
                >
                    CREAR USUARIO
                </button>

                <button
                    className={`btn btn-general-red d-block w-100 mt-4 mb-5`}
                    style={{ fontWeight: 'bolder', height: '50px' }}
                    onClick={() => navigate('/')}
                >
                    CANCELAR
                </button>

            </Container>
        </Container>
    )
}
