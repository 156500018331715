import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type propsType = {
    closeModal: () => void;
}

export const NotificationsModal: FC<propsType> = ({ closeModal }) => {

    return (
        <Modal>
            <Modal.Header>
                <Modal.Title>NOTIFICACIONES POR WHATSAPP</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
        
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className={'btn btn-black'} onClick={closeModal}>Cerrar</button>
            </Modal.Footer>
        </Modal>
    )
}