"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.priorities = exports.daysOfTheWeekP = void 0;
///////////////////////////////////////////////////////////////////////////////////////////////////
exports.daysOfTheWeekP = ['Domingos', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados'];
exports.priorities = [
    { label: 'Alta', value: 1 },
    { label: 'Media', value: 2 },
    { label: 'Baja', value: 3 }
];
