import { FC } from 'react';

type propsType = {
    closeModal: () => void;
    handleDelete: () => void;
}

export const MeetingAttendanceFormDelete: FC<propsType> = ({ closeModal, handleDelete }) => {
    return (
        <div className={`modal fade show d-block`}
            tabIndex={-1}
            role={'dialog'}
        >
            <div className={'modal-dialog'} role={'document'}>
                <div className={'modal-content'}>
                    <div className={'modal-header'}>
                        <h5 className={'modal-title'}>Confirmar Eliminación</h5>
                        <button type={'button'}
                            className={'btn-close'}
                            onClick={closeModal}
                        ></button>
                    </div>
                    <div className={'modal-body'}>
                        <p>¿Estás seguro de que quieres eliminar esta asistencia?</p>
                    </div>
                    <div className={'modal-footer'}>
                        <button type={'button'}
                            className={'btn btn-danger'}
                            onClick={handleDelete}
                        >
                            Eliminar
                        </button>
                        <button type={'button'}
                            className={'btn btn-secondary'}
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
