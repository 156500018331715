import { HTHAssignments } from './subcomponents/HTHAssignments';
import { HTHBuildings } from './subcomponents/HTHBuildings';
import { HTHConfig } from './subcomponents/HTHConfig';
import { HTHMap } from './subcomponents/HTHMap';
import { getHTHTerritoriesForStatisticsService, getUsersService } from '../../../services';
import { H2, Hr, Loading } from '../../_commons';
import { io, Socket } from 'socket.io-client';
import { SERVER, socketIoEvents } from '../../../app-config';
import { setValuesAndOpenAlertModalReducer } from '../../../store';
import { typeHTHTerritory, typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetCurrentUser, useGetIsDarkMode } from '../../../custom-hooks';

const socket: Socket = io(SERVER, { withCredentials: true });

export const AdminsHTH = () => {
    const [hthTerritories, setHthTerritories] = useState<typeHTHTerritory[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const [showOption, setShowOption] = useState<0 | 1 | 2 | 3 | 4>(0);
    const [users, setUsers] = useState<typeUser[] | null>(null);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const user = useGetCurrentUser()

    useEffect(() => {
        getUsersService().then(us => {
            setUsers(us);
        });
        getHTHTerritoriesForStatisticsService().then(territories => {
            setIsLoading(false);
            if (!territories) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron obtener los territorios de Casa en Casa. Refrescar. Ver si hay internet.",
                    animation: 2
                }));
                return;
            }
            setHthTerritories(territories);
        });
    }, [dispatch, refreshCounter]);

    useEffect(() => {
        socket.on(socketIoEvents.userChange, (congregation: number) => {
            if (congregation !== user.congregation) return;
            setRefreshCounter(x => x + 1);
        });
        return () => { socket.off(socketIoEvents.userChange) };
    }, [user.congregation]);

    if (isLoading) return <Loading mt={'80px'} />;

    return (
        <div className={'container'}>
            <H2 title={'ADMINISTRACIÓN CASA EN CASA'} />
            {!!hthTerritories?.length ?
                <div>
                    <button className={`btn btn-${showOption === 1 ? 'general-blue' : 'general-red'} btn-size12 d-block mx-auto mt-4 mb-0`}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 1 ? 0 : 1)}
                    >
                        {showOption === 1 ? 'Viendo Asignaciones' : 'Ver Asignaciones'}
                    </button>
                    <button className={`btn btn-${showOption === 2 ? 'general-blue' : 'general-red'} btn-size12 d-block mx-auto mt-2 mb-0`}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 2 ? 0 : 2)}
                    >
                        {showOption === 2 ? 'Viendo Edificios' : 'Ver Edificios'}
                    </button>
                    <button className={`btn btn-${showOption === 3 ? 'general-blue' : 'general-red'} btn-size12 d-block mx-auto mt-2 mb-0`}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 3 ? 0 : 3)}
                    >
                        {showOption === 3 ? 'Viendo Predicación de Edificios' : 'Ver Predicación de Edificios'}
                    </button>
                    <button className={`btn btn-${showOption === 4 ? 'general-blue' : 'general-red'} btn-size12 d-block mx-auto mt-2 mb-0`}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 4 ? 0 : 4)}
                    >
                        {showOption === 4 ? 'Viendo Configuración' : 'Ver Configuración'}
                    </button>

                    <Hr classes={'mt-5 mx-auto'} />

                    {showOption === 1 &&
                        <HTHAssignments
                            hthTerritories={hthTerritories}
                            users={users}
                        />
                    }
                    {showOption === 2 &&
                        <HTHBuildings
                            hthTerritories={hthTerritories}
                        />
                    }
                    {showOption === 3 &&
                        <HTHMap
                            hthTerritories={hthTerritories}
                        />
                    }
                    {showOption === 4 &&
                        <HTHConfig />
                    }
                </div>
                :
                <h4 className={`text-center mt-5 ${isDarkMode ? 'text-white' : ''}`}>
                    No hay datos
                </h4>
            }
        </div>
    )
}
