import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserFromLSService, removeTokenFromLSService, setConfigToLSService, setUserToLSService } from '../services/localStorageServices';
import { typeUser, unauthenticatedConfig, unauthenticatedUser } from '@monorepo/models';

export const userSlice = createSlice({
    name: 'user',
    initialState: getUserFromLSService() ?? unauthenticatedUser,
    reducers: {
        logoutReducer: (state) => {
            removeTokenFromLSService();
            setUserToLSService(unauthenticatedUser);
            setConfigToLSService(unauthenticatedConfig);
            state = unauthenticatedUser;
            return state;
        },
        refreshUserReducer: (state, action: PayloadAction<typeUser>) => {
            setUserToLSService(action.payload);
            state = action.payload;
            return state;
        }
    }
});

export const { logoutReducer, refreshUserReducer } = userSlice.actions;
