import { getHeaders } from '.';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typePublicMinistryDoc, typeResponseData } from '@monorepo/models';

class PublicMinistryServices {
    private _base = pointer.publicMinistry;
    //
    GetPublicMinistryData = async (): Promise<typePublicMinistryDoc | null> => {
        try {
            if (!getTokenFromLSService()) throw new Error('No autenticado');
            const response = await fetch(this._base, {
                method: 'GET',
                headers: getHeaders()
            });
            const data = await response.json() as typeResponseData;
            return data?.publicMinistryDoc ?? null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
}

export const publicMinistryServices = new PublicMinistryServices();
