"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidPhoneNumber = exports.isToday = exports.getTimeFromPeriodObject = exports.getCurrentLocalDate = void 0;
const getCurrentLocalDate = () => {
    const argDate = new Date();
    argDate.setTime(argDate.getTime() - 3 * 60 * 60 * 1000);
    return argDate.toISOString().split('T')[0];
};
exports.getCurrentLocalDate = getCurrentLocalDate;
const getTimeFromPeriodObject = (period, mode = 1) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return mode === 1 ?
        `${(_a = period.startHour) === null || _a === void 0 ? void 0 : _a.toString().padStart(2, '0')}:${(_b = period.startMinutes) === null || _b === void 0 ? void 0 : _b.toString().padStart(2, '0')}-${(_c = period.endHour) === null || _c === void 0 ? void 0 : _c.toString().padStart(2, '0')}:${(_d = period.endMinutes) === null || _d === void 0 ? void 0 : _d.toString().padStart(2, '0')}`
        :
            `desde las ${(_e = period.startHour) === null || _e === void 0 ? void 0 : _e.toString().padStart(2, '0')}:${(_f = period.startMinutes) === null || _f === void 0 ? void 0 : _f.toString().padStart(2, '0')} hs. hasta las ${(_g = period.endHour) === null || _g === void 0 ? void 0 : _g.toString().padStart(2, '0')}:${(_h = period.endMinutes) === null || _h === void 0 ? void 0 : _h.toString().padStart(2, '0')} hs.`;
};
exports.getTimeFromPeriodObject = getTimeFromPeriodObject;
const isToday = (timestamp) => {
    const argDate = timestamp ? new Date(timestamp) : new Date();
    argDate.setTime(argDate.getTime() - 3 * 60 * 60 * 1000);
    const currentLocalDateFromTimestamp = argDate.toISOString().split('T')[0];
    return currentLocalDateFromTimestamp === (0, exports.getCurrentLocalDate)();
};
exports.isToday = isToday;
const isValidPhoneNumber = (phoneNumber) => /^\+\d{11,14}$/.test(phoneNumber);
exports.isValidPhoneNumber = isValidPhoneNumber;
