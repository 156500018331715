import { FC } from 'react';
import { generalBlue } from '../../../app-config';
import { normalizeString } from '../../../services';
import { typeBoardSection } from '@monorepo/models';
import { useGetIsMobile } from '../../../custom-hooks';
import { useNavigate } from 'react-router';

type propsType = {
    currentSection: typeBoardSection | null;
    sections: typeBoardSection[];
}

export const BoardSideBar: FC<propsType> = ({ currentSection, sections }) => {
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();

    return (
        <div className={'pt-1 pb-3 px-2'} style={{ backgroundColor: isMobile ? '' : 'lightgray' }}>
            <style>{`.list-group-item.active {background-color: ${generalBlue} !important;}`}</style>
            <ul className={'list-group mt-3'}>
                {sections?.sort((i1, i2) => i1.order - i2.order).map(section =>
                    <li className={`list-group-item fw-bold pointer ${currentSection?.id === section.id ? 'active' : ''}`}
                        key={section.title}
                        onClick={() => navigate(`/tablero/${normalizeString(section.title)}`)}
                        onMouseOver={e => (e.target as Element).classList.add('btn-general-blue')}
                        onMouseOut={e => (e.target as Element).classList.remove('btn-general-blue')}
                    >
                        {section.title}
                    </li>
                )}
            </ul>
        </div>
    )
}
