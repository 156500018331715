import { getHeaders } from '.';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeCartAssignment, typeCartsDoc, typeCartParticipant, typeCartPeriod, typeCartPoint, typeResponseData } from '@monorepo/models';

const base = pointer.cart;

export const addCartAssignmentService = async (assignment: typeCartAssignment): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/assignment`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ assignment })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addCartEnabledPeriodService = async (point: typeCartPoint, period: typeCartPeriod): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/period`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ period, point })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addCartParticipantService = async (participant: typeCartParticipant): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/participant`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ participant })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addCartPointService = async (point: typeCartPoint): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/point`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ point })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteCartAssignmentService = async (assignment: typeCartAssignment): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/assignment`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ assignment })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteCartEnabledPeriodService = async (point: typeCartPoint, period: typeCartPeriod): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/period`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ period, point })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteCartParticipantService = async (participant: typeCartParticipant): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/participant`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ participant })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteCartPointService = async (point: typeCartPoint): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/point`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ point })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const getCartDocService = async (): Promise<typeCartsDoc | null> => {
    if (!getTokenFromLSService()) return null;
    try {
        const response = await fetch(base, {
            headers: getHeaders()
        });
        const data = await response.json() as typeResponseData;
        return data?.cartDoc ?? null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updateCartParticipantService = async (participant: typeCartParticipant): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/participant`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ participant })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const updateCartPointService = async (point: typeCartPoint): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/point`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ point })
        });
        const data = await response.json() as typeResponseData;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}
