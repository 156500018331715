import { BsPeople, BsPinMap, BsPen } from 'react-icons/bs';
import { CartAssignment } from './assignment/CartAssignment';
import { CartParticipant } from './participant/CartParticipant';
import { CartPoint } from './point/CartPoint';
import { getCartDocService } from '../../../services/cartServices';
import { H2, Loading } from '../../_commons';
import { typeCartsDoc } from '@monorepo/models';
import { useEffect, useState } from 'react';
import { useGetIsDarkMode } from '../../../custom-hooks';
import { useNavigate } from 'react-router';

type typeCarritosSection = '' | 'assignments' | 'participants' | 'points';

export const AdminsCarts = () => {
    const [cartDoc, setCartDoc] = useState<typeCartsDoc | null>(null);
    const [currentSection, setCurrentSection] = useState<typeCarritosSection>('');
    const [isLoading, setIsLoading] = useState(true);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const isDarkMode = useGetIsDarkMode();
    const navigate = useNavigate();

    useEffect(() => {
        getCartDocService().then(co => {
            setIsLoading(false);
            if (co) setCartDoc(co);
        });
    }, [refreshCounter]);

    useEffect(() => {
        const relativePath = window.location.pathname;
        let section: typeCarritosSection = '';
        if (relativePath === '/admins/carritos/puntos') section = 'points';
        if (relativePath === '/admins/carritos/participantes') section = 'participants';
        if (relativePath === '/admins/carritos/asignaciones') section = 'assignments';
        setCurrentSection(section);
    // eslint-disable-next-line
    }, [window.location.pathname]);

    if (isLoading) return <Loading mt={'80px'} />;

    if (!cartDoc) return <div className={'mt-5'}>No se pudieron recuperar los datos</div>;

    return (
        <div>
            <H2 title={'CARRITOS'} />
            <section className={'mb-4 px-4 mx-auto'}>
                {currentSection === '' &&
                    <div className={'d-grid gap-3 m-auto mt-2'} style={{ maxWidth: '600px' }}>
                        <button className={'btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center mt-4'}
                            onClick={() => navigate('puntos')}
                        >
                            <BsPinMap className={'me-2'} />
                            Puntos
                        </button>
                        <button className={'btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center'}
                            onClick={() => navigate('participantes')}
                            >
                            <BsPeople className={'me-2'} />
                            Participantes
                        </button>
                        <button className={'btn btn-general-red btn-size12 d-flex align-items-center justify-content-center'}
                            style={{ height: '65px' }}
                            onClick={() => navigate('asignaciones')}
                        >
                            <BsPen className={'me-2'} />
                            <h3 className={'mb-0'}>Asignaciones</h3>
                        </button>
                    </div>
                }
            </section>

            {!!currentSection &&
                <div className={`card shadow ${isDarkMode ? 'bg-dark text-white' : ''} mx-auto`}>
                    {currentSection === 'points' &&
                        <CartPoint
                            cartDoc={cartDoc}
                            setRefreshCounter={setRefreshCounter}
                        />
                    }
                    {currentSection === 'participants' &&
                        <CartParticipant
                            cartDoc={cartDoc}
                            setRefreshCounter={setRefreshCounter}
                        />
                    }
                    {currentSection === 'assignments' &&
                        <CartAssignment
                            cartDoc={cartDoc}
                            setRefreshCounter={setRefreshCounter}
                        />
                    }
                </div>
            }
        </div>
    )
}
