import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { daysOfTheWeekS, generalBlue, months } from '../../../app-config';
import { H2, HorizontalSelector, Loading } from '../../_commons';
import { publicMinistryServices } from 'src/services';
import { typePublicMinistryMonthlyService } from '@monorepo/models';
import { useEffect, useMemo, useState } from 'react';
import { useGetIsDarkMode } from '../../../custom-hooks';

type typeOption = 'normal' | 'byDate';

const options: typeOption[] = ['normal', 'byDate'];

const getDaysOfMonth = (month: number, year: number) => new Date(year, month + 1, 0).getDate();

export const AdminsPublicMinistry = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [radioValue, setRadioValue] = useState<typeOption>('normal');
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [services, setServices] = useState<typePublicMinistryMonthlyService[] | null>(null);
    const isDarkMode = useGetIsDarkMode();

    const service: typePublicMinistryMonthlyService | null = useMemo(() =>
        services?.find(s => s.year === currentDate.getFullYear() && s.month === currentDate.getMonth()) ?? null
    , [currentDate, services]);

    const previousMonth = () => {
        const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        setCurrentDate(prevMonth);
    }

    const nextMonth = () => {
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        setCurrentDate(nextMonth);
    }

    useEffect(() => {
        publicMinistryServices.GetPublicMinistryData().then(_publicMinistryDoc => {
            console.log(_publicMinistryDoc);
            
            setIsLoading(false);
            if (_publicMinistryDoc) {
                setServices(_publicMinistryDoc.monthlyServices);
            }
        });
    }, [refreshCounter]);

    if (isLoading) return <Loading mt={'40px'} />;

    if (!services) return <div className={'mt-5'}>No se pudieron recuperar los datos</div>;

    return (
        <>
        <H2 title={'SALIDAS DE PREDICACIÓN'} />

        <HorizontalSelector
            label={`${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`}
            setNext={nextMonth}
            setPrevious={previousMonth}
        />

        {service ?
            <div>
                <h2>{months[service.month]} {service.year}</h2>
                <div className={'row px-4 mt-3'}>
                    <div className={'col-lg'}>
                        <ButtonGroup className={'d-flex flex-row w-100 mt-3 mb-2'}>
                            {options.map((radio, idx) =>
                                <ToggleButton key={idx}
                                    id={`radio-${idx}`}
                                    type={'radio'}
                                    className={`${radioValue === radio ? '' : 'bg-secondary'} py-2`}
                                    style={{ backgroundColor: radioValue === radio ? generalBlue : undefined }}
                                    checked={radioValue === radio}
                                    value={radio}
                                    onChange={e => setRadioValue(e.currentTarget.value as typeOption)}
                                >
                                    {radio === radioValue ? 'Viendo' : 'Ver'} {radio === 'normal' ? 'Normal' : 'por Día'}
                                </ToggleButton>
                            )}
                        </ButtonGroup>
                    </div>
                </div>
                {radioValue === 'normal' ?
                    <Services service={service} />
                    :
                    <ServicesByDate service={service} />
                }
            </div>
            :
            <div>
                <button className={'btn btn-general-red'}>
                    Crear Salidas de {months[currentDate.getMonth()]}
                </button>
            </div>
        }

        {/* S-13 */}

        </>
    )
}

const Services = ({ service }: { service: typePublicMinistryMonthlyService }) => {
    const body = (
        service.services.map(s =>
            <tr key={s.id}>
                <td>{daysOfTheWeekS[s.weekday ?? 0]} {s.day}</td>
                <td>{s.hour.toString().padStart(2, '0')}:{s.minutes.toString().padStart(2, '0')}</td>
                <td>{s.address}</td>
                <td>{s.captain1UserId}</td>
                <td>{s.territories.join(', ')}</td>
                <td>{s.observation}</td>
            </tr>
        )
    );
    return (
        <Table body={body} />
    )
}

const ServicesByDate = ({ service }: { service: typePublicMinistryMonthlyService }) => {
    const body = (
        Array.from({ length: getDaysOfMonth(service.month, service.year) }, (_, i) => i + 1).map(d =>
            <tr>
                <td>{daysOfTheWeekS[new Date(service.year, service.month, d).getDay()]} {d}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        )
    );

    return (
        <Table body={body} />
    )
}

const Table = ({ body }: { body: JSX.Element[]; }) => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <div className={'table-responsive'}>
            <table className={`table table-bordered ${isDarkMode ? 'table-dark' : 'table-light'}`}>
                <thead>
                    <tr>
                        <th>Día</th>
                        <th>Hora</th>
                        <th>Lugar</th>
                        <th>Capitán</th>
                        <th>Territorios</th>
                        <th>Observaciones</th>
                    </tr>
                </thead>
                <tbody>
                    {body}
                </tbody>
            </table>
        </div>
    )
}
