import { FC } from 'react';

type propsType = {

}

export const MeetingAttendanceFomPrint: FC<propsType> = ({  }) => {

    const handlePrintPDF = () => {
        // const doc = new jsPDF();
        // doc.text('Historial de Asistencia', 10, 10);
        // filteredHistorial.forEach((entry, index) => {
        //     doc.text(
        //     `${index + 1}. Fecha: ${new Date(entry.date).toLocaleDateString(
        //         'es-ES'
        //     )}, Presencial: ${entry.presencial}, Zoom: ${entry.zoom}, YouTube: ${
        //         entry.youtube
        //     }, Notas: ${entry.notas || ''}`,
        //     10,
        //     20 + index * 10
        //     );
        // });
        // doc.save('historial_asistencia.pdf');
    }

    return (
        <div className={'mb-4 text-center'}>
            <button className={'btn btn-general-blue px-4'} onClick={handlePrintPDF}>
                Imprimir en PDF
            </button>
        </div>
    )
}
