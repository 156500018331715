import { appName } from '../../../app-config';
import { Container, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { FC } from 'react';
import { setUserPersonalDataService } from '../../../services';
import { setValuesAndOpenAlertModalReducer } from '../../../store';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, useGetIsMobile } from '../../../custom-hooks';

type propsType = {
    closeModal: () => void;
}

export const UserPersonalData: FC<propsType> = ({ closeModal }) => {
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    const deletePhoneNumber = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar el número de teléfono ${user.phoneNumber} de la aplicación y ya no se podrán recibir en WhatsApp notificaciones, recordatorios ni opciones de autogestión`,
            animation: 3,
            execution: async () => {
                const response = await setUserPersonalDataService(user.id, user.email, user.name ?? '', user.lastName ?? '', '');
                if (!response?.success) {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        mode: 'alert',
                        title: 'Confirmar',
                        message: 'No se pudo eliminar el número de teléfono',
                        animation: 2
                    }));
                } else {
                    window.location.reload();
                }
            }
        }));
    }

    return (
        <Modal show={true}
            centered
            fullscreen={'md-down'}
            onHide={closeModal}
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Modal.Title>Datos personales en {appName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ maxWidth: '500px', padding: isMobile ? '35px 30px 0' : '35px 0' }}>
                    <FloatingLabel
                        className={'mb-3 text-dark'}
                        label={'Dirección de Correo Electrónico'}
                    >
                        <Form.Control
                            type={'email'}
                            className={'form-control'}
                            placeholder={''}
                            value={user.email}
                            disabled={true}
                        />
                    </FloatingLabel>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <FloatingLabel
                                className={'mb-3 text-dark'}
                                label={'Nombre'}
                            >
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    placeholder={''}
                                    value={user.name ?? '-'}
                                    disabled={true}
                                />
                            </FloatingLabel>
                        </div>
                        <div className='col-6'>
                            <FloatingLabel
                                className={'mb-3 text-dark'}
                                label={'Apellido'}
                            >
                                <Form.Control
                                    type={'text'}
                                    className={'form-control'}
                                    placeholder={''}
                                    value={user.lastName ?? '-'}
                                    disabled={true}
                                />
                            </FloatingLabel>
                        </div>
                    </div>
                    {!!user.phoneNumber &&
                        <>
                        <FloatingLabel className={'mb-3 text-dark'}
                            label={'Teléfono celular'}
                        >
                            <Form.Control type={'text'}
                                className={'form-control'}
                                placeholder={''}
                                value={user.phoneNumber}
                                disabled={true}
                            />
                        </FloatingLabel>
                        <div className={'text-center'}>
                            <button className={'btn btn-general-red py-1 mt-1'}
                                style={{ width: '350px' }}
                                onClick={deletePhoneNumber}
                            >
                                Eliminar mi número de teléfono
                            </button>
                        </div>
                        </>
                    }
                    <p className={'small'}>
                        Nombre, apellido y número de teléfono se guardan con una capa extra de encriptación para evitar robos de datos personales. El número de teléfono solo es conocido internamente por el sistema y no puede ser consultado desde la aplicación.
                    </p>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <button className={'btn btn-secondary'}
                    onClick={closeModal}
                >
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
