import { assignTLPTerritoryService } from '../../../../services';
import { BsTrash } from 'react-icons/bs';
import { Container, Modal } from 'react-bootstrap';
import { FC, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../../store';
import { Hr } from '../../../_commons';
import { typeTelephonicTerritory, typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode } from '../../../../custom-hooks';
import Select from 'react-select';

type propsType = {
    closeModal: () => void;
    t: typeTelephonicTerritory;
    users: typeUser[] | null;
}

export const TelephonicAssignmentModal: FC<propsType> = ({ closeModal, t, users }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userToAdd, setUserToAdd] = useState<typeUser | null>(null);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();

    const assign = (): void => {
        if (!userToAdd) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a asignar el territorio ${t.territoryNumber} a ${userToAdd.name} ${userToAdd.lastName} - ${userToAdd.email}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                setIsLoading(true);
                const success = await assignTLPTerritoryService(userToAdd, t.number, null, false);
                setIsLoading(false);
                dispatch(hideLoadingModalReducer());
                if (!success) {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        mode: 'alert',
                        title: 'Error',
                        message: 'Algo falló al cambiar las asignaciones',
                        animation: 2
                    }));
                }
                setUserToAdd(null);
            }
        }));
    }

    const unassign = (user: typeUser, toUnassign: number): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a desasignar el territorio ${toUnassign} a ${user.name} ${user.lastName} - ${user.email}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                setIsLoading(true);
                const success = await assignTLPTerritoryService(user, null, toUnassign, false);
                setIsLoading(false);
                dispatch(hideLoadingModalReducer());
                if (!success) {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        mode: 'alert',
                        title: 'Error',
                        message: 'Algo falló al cambiar las asignaciones',
                        animation: 2
                    }));
                }
            }
        }));
    }

    return (
        <Modal show={true}
            fullscreen={'md-down'}
            onHide={closeModal}
            size={'lg'}
        >
            <Modal.Header className={isDarkMode ? 'bg-dark text-white' : ''} closeButton>
                <Modal.Title>Territorio {t.territoryNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark' : ''}>
                <Container style={{ maxWidth: '500px' }}>
                    {!!users?.filter(u => u.phoneAssignments?.includes(t.number))?.length ?
                        users?.filter(u => u.phoneAssignments?.includes(t.number)).map(u =>
                            <div key={u.id}
                                className={`d-flex justify-content-between pointer hover-primary ${isDarkMode ? 'text-white' : ''} py-1 mb-1`}
                                onClick={() => isLoading ? null : unassign(u, parseInt(t.territoryNumber))}
                            >
                                <div>
                                    {u.name} {u.lastName}
                                </div>
                                <div className={'me-2'}>
                                    {!isLoading &&
                                        <BsTrash size={'1.3rem'} />
                                    }
                                </div>
                            </div>
                        )
                        :
                        '-'
                    }

                    <Hr />

                    <div className={'d-flex justify-content-start mt-4 mb-3'}>
                        <button className={'btn btn-general-blue me-3'}
                            style={{ width: '200px' }}
                            onClick={assign}
                            disabled={!userToAdd || isLoading}
                        >
                            Agregar
                        </button>
                        <div className={'w-100'}>
                            <Select options={users?.filter(u => !t.assignments.includes(u.id)) ?? []}
                                isClearable
                                getOptionLabel={u => `${u.name} ${u.lastName}`}
                                getOptionValue={u => u?.id?.toString()}
                                value={userToAdd}
                                onChange={u => setUserToAdd(u)}
                            />
                        </div>
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer className={isDarkMode ? 'bg-dark' : ''}>
                <button className={'btn btn-general-red'} onClick={closeModal}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
