import { ButtonGroup, Container, ToggleButton } from 'react-bootstrap';
import { daysOfTheWeekP, generalBlue, googleMapConfig, months } from '../../../../app-config';
import { FC, Fragment, useMemo, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { Loading } from '../../../_commons';
import { maskTheBlock } from '../../../../services';
import { typeHTHBuilding, typeHTHTerritory, typePolygon } from '@monorepo/models';
import { useGetConfig, useGetIsMobile } from '../../../../custom-hooks';

type propsType = {
    hthTerritories: typeHTHTerritory[];
}

const radios = [
    { name: 'Fecha Puntual', value: '1' },
    { name: 'Rango de Fechas', value: '2' },
    { name: 'Día de la Semana', value: '3' }
];

type typeOption = '1' | '2' | '3';

export const HTHMap: FC<propsType> = ({ hthTerritories }) => {
    const [date1, setDate1] = useState<number | null>(null);
    const [date2, setDate2] = useState<number | null>(null);
    const [markerToShow, setMarkerToShow] = useState<typePolygon | null>(null);
    const [month, setMonth] = useState<number>(new Date().getMonth());
    const [option, setOption] = useState<typeOption>('1');
    const [weekDay, setWeekDay] = useState<number>(1);
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const centerCoords = hthTerritories[0].map.centerCoords;
    const config = useGetConfig();
    const isMobile = useGetIsMobile();
    const map = useRef<any>();
    const mapLoader = useJsApiLoader(googleMapConfig);

    const buildings: typeHTHBuilding[] = useMemo(() => {
        let hs: typeHTHBuilding[] = [];
        if (option === '1' && !date1) return hs;
        if (option === '2' && (!date1 || !date2)) return hs;
        hthTerritories.forEach(t => {
            t.map.polygons.forEach(p => {
                p.buildings?.forEach(b => {
                    if (!!b.coords) {
                        const x = { ...b, street: p.street, territoryNumber: t.territoryNumber }
                        if (option === '1' && date1) {
                            if (b.households?.some(h => h.onDates?.some(d => d > date1 && d < date1 + 24*60*60*1000))) {
                                hs.push(x);
                            }
                        } else if (option === '2' && date1) {
                            if (b.households?.some(h => h.onDates?.some(d => d > date1 && d < (date2 ?? 1)))) {
                                hs.push(x);
                            }
                        } else {
                            if (b.households?.some(h => h.onDates?.some(d => new Date(d).getDay() === weekDay && new Date(d).getMonth() === month && new Date(d).getFullYear() === year))) {
                                hs.push(x);
                            }
                        }
                    }
                });
            });
        });
        return hs;
    }, [date1, date2, hthTerritories, month, option, weekDay, year]);

    return (
        <div className={'mt-4'}>
            <style>{`.gm-style-iw-chr {height: 14px;}`}</style>
            <div className={'row py-4'}>
                <div className={'col-md-6'}>
                    <Container>
                        <ButtonGroup className={'w-100'}>
                            {radios.map((radio, idx) =>
                                <ToggleButton key={idx}
                                    type={'radio'}
                                    id={`radio-${idx}`}
                                    className={`${option === radio.value ? 'btn btn-general-blue' : 'btn btn-secondary'} py-2`}
                                    checked={option === radio.value}
                                    onChange={e => {
                                        const newValue = e.currentTarget.value as '1' | '2' | '3';
                                        if (newValue === '1') {
                                            setDate2(null);
                                        } else {
                                            if (date1) {
                                                const adjustedDate = new Date(date1);
                                                adjustedDate.setHours(23, 59, 59, 999);
                                                setDate2(adjustedDate.getTime());
                                            }
                                        }
                                        setOption(newValue);
                                    }}
                                    value={radio.value}
                                >
                                    {radio.name}
                                </ToggleButton>
                            )}
                        </ButtonGroup>
                    </Container>
                </div>
                {['1', '2'].includes(option) ?
                    <>
                    <div className={'col-md-3'}>
                        {/* <label className={'form-label'}>
                            {option === '1' ? 'Fecha' : 'Fecha Desde'}
                        </label> */}
                        <input type={'date'}
                            className={'form-control'}
                            value={date1 ? new Date(date1 - 3*60*60*1000).toISOString().split('T')[0] : ''}
                            onChange={e => {
                                const selectedDate = e.target.value ? new Date(e.target.value).getTime() + 3*60*60*1000: null;
                                setDate1(selectedDate);
                            }}
                        />
                    </div>
                    {option === '2' && date1 &&
                        <div className={'col-md-3'}>
                            {/* <label className={'form-label'}>
                                Fecha Hasta
                            </label> */}
                            <input type={'date'}
                                className={'form-control'}
                                value={date2 ? new Date(date2 - 3*60*60*1000).toISOString().split('T')[0] : ''}
                                onChange={e => {
                                    const selectedDate = e.target.value ? new Date(e.target.value).getTime() + 3*60*60*1000 : null;
                                    if (!selectedDate) return;
                                    if (selectedDate < date1) {
                                        setDate2(date1);
                                    } else {
                                        const adjustedDate = new Date(selectedDate);
                                        adjustedDate.setHours(23, 59, 59, 999);
                                        setDate2(adjustedDate.getTime());
                                    }
                                }}
                            />
                        </div>
                    }
                    </>
                    :
                    <>
                    <div className={'col-md-2'}>
                        <select className={'form-select'}
                            value={weekDay}
                            onChange={e => setWeekDay(parseInt(e.target.value))}
                        >
                            {daysOfTheWeekP.map((d, i) =>
                                <option key={d} value={i}>{d}</option>
                            )}
                        </select>
                    </div>
                    <div className={'col-md-2'}>
                        <select className={'form-select'}
                            value={month}
                            onChange={e => setMonth(parseInt(e.target.value))}
                        >
                            {months.map((m, i) =>
                                <option key={m} value={i}>{m}</option>
                            )}
                        </select>
                    </div>
                    <div className={'col-md-2'}>
                        <select className={'form-select'}
                            value={year}
                            onChange={e => setYear(parseInt(e.target.value))}
                        >
                            {[2022, 2023, 2024, 2025, 2026, 2027, 2028].map(y =>
                                <option key={y} value={y}>{y}</option>
                            )}
                        </select>
                    </div>
                    </>
                }
            </div>

            {((option === '1' && date1) || (option === '2' && date1 && date2) || option === '3') &&
                <>
                    {!mapLoader.isLoaded && <Loading />}

                    {mapLoader.loadError && <h3> Falló: {mapLoader.loadError.message} </h3>}
                
                    <GoogleMap
                        id={googleMapConfig.id}
                        mapContainerClassName={isMobile ? 'position-absolute' : 'd-block m-auto'}
                        mapContainerStyle={{
                            height: isMobile ? '600px' : '500px',
                            width: '93%'
                        }}
                        onLoad={mapInstance => {map.current = mapInstance}}
                        center={centerCoords}
                        zoom={17.8}
                        // onZoomChanged={() => setZoom(map.current?.getZoom())}
                        // options={{ center: centerCoords, zoom: zoom }}
                        // zoom={zoom}
                    >
                        
                        {buildings.map((h, i) =>
                            <Marker key={i}
                                // options={{ anchorPoint: new google.maps.Point(centerCoords.lat, centerCoords.lng) }}
                                position={h.coords ?? { lat: 1, lng: 2 }}
                                onClick={() => {}}
                                clickable={true}
                                label={`T${h.territoryNumber} ${h.street} ${h.streetNumber}`}
                            />
                        )}

                        {hthTerritories.map(t =>
                            <div key={t.territoryNumber}>
                                {t.map.polygons.map(currentFace =>
                                    <Fragment key={currentFace.id}>
                                        <Polygon
                                            editable={false}
                                            draggable={false}
                                            path={[currentFace.coordsPoint1, currentFace.coordsPoint2, currentFace.coordsPoint3]}
                                            onClick={() => setMarkerToShow(currentFace)}
                                            options={{
                                                clickable: true,
                                                fillColor: generalBlue,
                                                fillOpacity: 0.9,
                                                strokeColor: '',
                                                strokeOpacity: 0.8,
                                                strokePosition: google.maps.StrokePosition.INSIDE,
                                                strokeWeight: 7
                                            }}
                                        />
                                        {markerToShow?.id === currentFace.id &&
                                            <InfoWindow
                                                position={{
                                                    lat: (currentFace.coordsPoint1.lat + currentFace.coordsPoint2.lat + currentFace.coordsPoint3.lat) / 3 + 0.00005,
                                                    lng: (currentFace.coordsPoint1.lng + currentFace.coordsPoint2.lng + currentFace.coordsPoint3.lng) / 3 - 0.0001
                                                }}
                                            >
                                                <div style={{
                                                    border: '3px solid #ffffff',
                                                    borderRadius: '5px',
                                                    font: '15px Sans-serif',
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    width: '176px'
                                                }}>
                                                    <div>Territorio {t.territoryNumber}</div>
                                                    <div className={'small mt-1'}>
                                                        Manzana {maskTheBlock(currentFace.block, config.usingLettersForBlocks)} - {currentFace.street}
                                                    </div>
                                                </div>
                                            </InfoWindow>
                                        }
                                    </Fragment>
                                )}
                            </div>
                        )}
                    </GoogleMap>
                </>
            }
        </div>
    )
}
