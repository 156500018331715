import { getHeaders } from '.';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeBoardItem, typeBoardItemType, typeBoardSection, typeResponseData } from '@monorepo/models';
import axios from 'axios';

const base = pointer.board;

export const addBoardSectionService = async (title: string): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(base, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ title })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteBoardSectionService = async (section: typeBoardSection): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(base, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({
                section: {
                    id: section.id,
                    items: section.items.map(i => ({ ...i, data: null })),
                    order: section.order,
                    title: section.title
                }
            })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const editBoardSectionTitleService = async (sectionId: number, sectionTitle: string, title: string): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({
                sectionId,
                sectionTitle,
                title
            })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}
export const getBoardSectionsService = async (): Promise<typeBoardSection[] | null> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(base, {
            method: 'GET',
            headers: getHeaders()
        });
        const data = await response.json() as typeResponseData | null;
        if (!data?.success || !data?.boardSections) return null;
        return data.boardSections;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const reorderBoardSectionsService = async (sections: typeBoardSection[]): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(base, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({
                sections: sections.map(section => ({
                    id: section.id,
                    items: section.items.map(i => ({ ...i, data: null })),
                    order: section.order,
                    title: section.title
                }))
            })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

// ITEMS

export const addBoardItemService = async (sectionId: number, sectionTitle: string, itemType: typeBoardItemType, title: string | null, content: string | null, file: File | null): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        if (file) {
            const formData = new FormData();
            formData.append('pdf', file);
            const response = await axios.post(`${base}/item?sectionId=${sectionId}&sectionTitle=${sectionTitle}&itemType=${itemType}`, formData, {
                headers: {
                    ...getHeaders(),
                    'Content-Type': 'multipart/form-data'
                }
            });
            const data = response.data as typeResponseData | null;
            return !!data?.success;
        } else {
            const response = await fetch(`${base}/item?sectionId=${sectionId}&sectionTitle=${sectionTitle}&itemType=${itemType}`, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({ content, title })
            });
            const data = await response.json() as typeResponseData | null;
            return !!data?.success;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const archiveBoardFileService = async (sectionId: number, sectionTitle: string, item: typeBoardItem, unarchive: boolean): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(`${base}/item`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({
                sectionId,
                sectionTitle,
                item: { ...item, data: null },
                unarchive
            })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteBoardItemService = async (sectionId: number, sectionTitle: string, item: typeBoardItem): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(`${base}/item`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({
                item: { ...item, data: null },
                sectionId,
                sectionTitle
            })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const getBoardArchivedFileService = async (sectionTitle: string, item: typeBoardItem, openingNewTab: boolean): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const itemJson = JSON.stringify({ ...item, data: null });
        const response = await fetch(`${base}/item?sectionTitle=${sectionTitle}&item=${itemJson}`, {
            method: 'GET',
            headers: getHeaders()
        });
        if (!response) return false;
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        if (openingNewTab) {
            window.open(url, '_blank');
        } else {
            const a = document.createElement('a');
            a.href = url;
            a.download = item.originalName ?? 'archivo.pdf';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
        window.URL.revokeObjectURL(url);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const reorderBoardItemsService = async (sectionId: number, sectionTitle: string, items: typeBoardItem[]): Promise<boolean> => {
    try {
        if (!getTokenFromLSService()) throw new Error('No autenticado');
        const response = await fetch(`${base}/item`, {
            method: 'PATCH',
            headers: getHeaders(),
            body: JSON.stringify({
                items: items.map(i => i.type === 'file' ? { ...i, data: null } : i),
                sectionId,
                sectionTitle
            })
        });
        const data = await response.json() as typeResponseData | null;
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}
